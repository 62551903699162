import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Media from "./Media";
import Size from "../core/Size";
import VideoFrameSizeEvent from "./models/VideoFrameSizeEvent";
import VideoTrackEvent from "./models/VideoTrackEvent";
import StatisticVideo from "../models/StatisticVideo";
import StatisticConnection from "../models/StatisticConnection";
import Utility from "../core/Utility";

export default abstract class VideoTrack {
  private readonly _frameSizeChanged = new EventOwner<VideoFrameSizeEvent>();

  private _statVideo: StatisticVideo;
  private _statPair: StatisticConnection;

  //private _bitrate: number = null;
  //private _bytesReceived: number = null;
  //private _bytesSent: number = null;
  //private _candidateAddress: string = null;
  //private _candidateRelayProtocol: string = null;
  //private _candidateType: string = null;
  //private _frameRate: number = null;
  private _frameSize: Size = null;
  //private _jitter: number = null;
  //private _keepAliveRequestsReceived: number;
  //private _keepAliveRequestsSent: number;
  //private _keepAliveResponsesReceived: number;
  //private _keepAliveResponsesSent: number;
  //private _keyFrameRequestsReceived: number = null;
  //private _keyFrameRequestsSent: number = null;
  //private _keyFramesReceived: number = null;
  //private _keyFramesSent: number = null;
  //private _nacksReceived: number = null;
  //private _nacksSent: number = null;
  //private _networkQuality: number = null;
  private _packetLoss: number = null;
  //private _packetsLost: number = null;
  //private _packetsReceived: number = null;
  //private _packetsSent: number = null;
  //private _remoteCandidateAddress: string = null;
  //private _remoteCandidateType: string = null;
  //private _roundTripTime: number = null;
  private _lastTargetBitrate: number = null;
  private _lastPacketsLost: number = null;
  private _lastPacketsSent: number = null;
  private _lastPacketsReceived: number = null;

  // Connection stats
  private _lastAvailableOutgoingBitrate: number = null;

  public get bitrate(): number { return this._statVideo?.BitrateActual; }
  public get bytesReceived(): number { return this._statVideo?.Rtp_BytesReceived; }
  public get bytesSent(): number { return this._statVideo?.Rtp_BytesSent; }
  public get candidateAddress(): string { return this._statPair?.Lcl_Ip; }
  public get candidateRelayProtocol(): string { return this._statPair?.Lcl_Protocol; }
  public get candidateType(): string { return this._statPair?.Lcl_CandidateType; }
  public get frameRate(): number { return this._statVideo?.FramerateActual; }
  public get frameSize(): Size { return this._frameSize; }
  public abstract get isDisabled(): boolean;
  public get isLocal(): boolean { return !this.isRemote; }
  public abstract get isMuted(): boolean;
  public abstract get isPaused(): boolean;
  public abstract get isRemote(): boolean;
  public get jitter(): number { return this._statVideo?.Rtp_Jitter; }
  public get keepAliveRequestsReceived(): number { return this._statPair?.Pair_RequestsReceived; }
  public get keepAliveRequestsSent(): number { return this._statPair?.Pair_RequestsSent; }
  public get keepAliveResponsesReceived(): number { return this._statPair?.Pair_ResponsesReceived; }
  public get keepAliveResponsesSent(): number { return this._statPair?.Pair_ResponsesSent; }
  public get keyFrameRequestsReceived(): number | null { return this._statVideo?.getKeyFrameRequestCount(); }
  public get keyFrameRequestsSent(): number | null { return this._statVideo?.getKeyFrameRequestCount(); }
  public get keyFramesReceived(): number { return this._statVideo?.Rtp_KeyFramesDecoded; }
  public get keyFramesSent(): number { return this._statVideo?.Rtp_KeyFramesDecoded; }
  public abstract get media(): Media;
  public get nacksReceived(): number { return this._statVideo?.Rtp_NackCount; }
 // public get nacksSent(): number { return this._nacksSent; }
  //public get networkQuality(): number { return this._networkQuality; }
  public get packetLoss(): number {
    if (this.isRemote) {
      if (Utility.isNullOrUndefined(this._statVideo?.Rtp_PacketsLost) || Utility.isNullOrUndefined(this._statVideo?.Rtp_PacketsReceived)) return null;
      if (this._statVideo?.Rtp_PacketsLost == this._lastPacketsLost && this._statVideo?.Rtp_PacketsReceived == this._lastPacketsReceived) return 0;
      const packetsLostDelta = this._statVideo?.Rtp_PacketsLost - this._lastPacketsLost;
      const packetsReceivedDelta = this._statVideo?.Rtp_PacketsReceived - this._lastPacketsReceived;
      if (packetsReceivedDelta == 0) return 0;
      const packetLoss = packetsLostDelta / packetsReceivedDelta;
      this._packetLoss = packetLoss;
    } else {
      if (Utility.isNullOrUndefined(this._statVideo?.Rtp_PacketsLost) || Utility.isNullOrUndefined(this._statVideo?.Rtp_PacketsSent)) return null;
      if (this._statVideo?.Rtp_PacketsLost == this._lastPacketsLost && this._statVideo?.Rtp_PacketsSent == this._lastPacketsSent) return 0;
      const packetsLostDelta = this._statVideo?.Rtp_PacketsLost - this._lastPacketsLost;
      const packetsSentDelta = this._statVideo?.Rtp_PacketsSent - this._lastPacketsSent;
      if (packetsSentDelta == 0) return 0;
      const packetLoss = packetsLostDelta / packetsSentDelta;
      this._packetLoss = packetLoss;
    }

    return this._packetLoss;
  }

  public get packetsLost(): number { return this._statVideo?.Rtp_PacketsLost; }
  public get packetsReceived(): number { return this._statVideo?.Rtp_PacketsReceived; }
  public get packetsSent(): number { return this._statVideo?.Rtp_PacketsSent; }
  public get remoteCandidateAddress(): string { return this._statPair?.Rmt_Ip; }
  public get remoteCandidateType(): string { return this._statPair?.Rmt_CandidateType; }
  public get roundTripTime(): number { return this._statPair?.Pair_RoundTripTimeCurrent; }
  public abstract get spatialLayerIndex(): number;
  public abstract get stream(): MediaStreamTrack;
  public abstract get temporalLayerIndex(): number;

  public get availableOutgoingBitrate(): number { return this._statPair?.Pair_AvailableOutgoing; }
  public get targetBitrate(): number { return this._statVideo?.Rtp_TargetBitrate; }

  /** @event */
  public get frameSizeChanged(): EventOwner<VideoFrameSizeEvent> { return this._frameSizeChanged; }
  /** @event */
  public abstract get streamBound(): EventOwnerAsync<VideoTrackEvent>;
  /** @event */
  public abstract get streamUnbound(): EventOwner<VideoTrackEvent>;

  private updateFrameSize(frameSize: Size): void {
    if (frameSize.width == this._frameSize?.width && frameSize.height == this._frameSize?.height) return;
    const previousFrameSize = this._frameSize;
    this._frameSize = frameSize;
    this._frameSizeChanged.dispatch({
      frameSize: frameSize,
      previousFrameSize: previousFrameSize,
      track: this,
    })
  }
  
  /** @internal */
  public updateStats(statVideo: StatisticVideo, statPair: StatisticConnection): void {
    if (this._statVideo) {
      this._lastTargetBitrate = this._statVideo.Rtp_TargetBitrate;
      this._lastPacketsLost = this._statVideo.Rtp_PacketsLost;
      this._lastPacketsSent = this._statVideo.Rtp_PacketsSent;
      this._lastPacketsReceived = this._statVideo.Rtp_PacketsReceived;
    }

    this._statVideo = statVideo;

    this._frameSize = { 
      height: this._statVideo?.ResolutionHeight, 
      width: this._statVideo?.ResolutionWidth 
    };

    if (statPair != null) {
      this._lastAvailableOutgoingBitrate = this._statPair?.Pair_AvailableOutgoing;
      this._statPair = statPair;
    }
  }

  /** @internal */
  public didAvailableOutgoingBitrateIncrease(): boolean {
    return this._statPair.Pair_AvailableOutgoing > this._lastAvailableOutgoingBitrate;
  }

  /** @internal */
  public didAvailableOutgoingBitrateDecrease(): boolean {
    return this._statPair.Pair_AvailableOutgoing < this._lastAvailableOutgoingBitrate;
  }

  /** @internal */
  public didTargetBitrateIncrease(): boolean {
    return this._statVideo.Rtp_TargetBitrate > this._lastTargetBitrate;
  }

  /** @internal */
  public didTargetBitrateDecrease(): boolean {
    return this._statVideo.Rtp_TargetBitrate < this._lastTargetBitrate;
  }

  /** @internal */
  public getSenderPacketLoss(): number | null {
    if (Utility.isNullOrUndefined(this._statVideo?.Rtp_PacketsLost) || Utility.isNullOrUndefined(this._statVideo?.Rtp_PacketsSent)) return null;
    if (this._statVideo?.Rtp_PacketsLost == this._lastPacketsLost && this._statVideo?.Rtp_PacketsSent == this._lastPacketsSent) return 0;
    const packetsLostDelta = this._statVideo?.Rtp_PacketsLost - this._lastPacketsLost;
    const packetsSentDelta = this._statVideo?.Rtp_PacketsSent - this._lastPacketsSent;
    if (packetsSentDelta == 0) return 0;
    const packetLoss = packetsLostDelta / packetsSentDelta;
    return packetLoss;
  }
}
