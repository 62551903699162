import { version } from "../Version";
import ApiResponse from "../api/models/ApiResponse";
import ClientInit from "./models/ClientInit";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import { RoomKeyLength } from "../core/validation/Room";
import TokenRequest from "./models/TokenRequest";
import TokenResponse from "./models/TokenResponse";
import Utility from "../core/Utility";

export default class Client {
  private readonly _httpClient: HttpClient;
  private readonly _init: ClientInit;
  
  public get maxRetries(): number { return this._httpClient.maxRetries; }
  public set maxRetries(value: number) { this._httpClient.maxRetries = value; }
  public get requestTimeout(): number { return this._httpClient.requestTimeout; }
  public set requestTimeout(value: number) { this._httpClient.requestTimeout = value; }
  public get clusterId(): string { return this._httpClient.clusterId; }

  public constructor(init: ClientInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.identity, "init.identity");
    this._init = init;
    this._httpClient = HttpClient.withTokenFactory(async () => {
      const token = await this._init.identity.token();
      return {
        baseUrl: new URL(token.clusterAssignment.meetingUrl).origin,
        value: token.token
      };
    });
  }

  public async getToken(request: TokenRequest, abortSignal?: AbortSignal): Promise<TokenResponse> {
    Guard.isNotNullOrUndefined(request, "request");
    if (Utility.isNullOrUndefined(request.roomId) && Utility.isNullOrUndefined(request.roomKey))
      throw new Error("Parameters 'request.roomId' and 'request.roomKey' cannot both be null or undefined.");
    if (request.roomKey) Guard.isLengthWithinBounds(request.roomKey, "room.key", RoomKeyLength.Max, RoomKeyLength.Min);
    return (<ApiResponse<TokenResponse>>await this._httpClient.post("/token", {
      clientType: "SDK",
      meetingType: request.meetingType,
      passcode: request.passcode,
      persistentAttendee: request.persistentAttendee,
      roomId: request.roomId,
      roomKey: request.roomKey,
      sdkVersion: version,
      sourceLanguage: "JS",
      userDisplayName: request.displayName
    }, abortSignal)).value;
  }
}