import AcceptedError from "./AcceptedError";
import BadRequestError from "./BadRequestError";
import BadRequestField from "./BadRequestField";
import Guard from "./Guard";
import HttpError from "./HttpError";
import HttpMethod from "./HttpMethod";
import HttpRequestAbortedError from "./HttpRequestAbortedError";
import HttpToken from "./models/HttpToken";
import InternalServerError from "./InternalServerError";
import Log from "../logging/Log";
import ServiceUnavailableError from "./ServiceUnavailableError";
import UnauthorizedError from "./UnauthorizedError";
import UnknownError from "./UnknownError";
import Utility from "./Utility";

export default class HttpClient {
  private static _createRequest = (url: URL, method: string, headers: Record<string, string>, signal: AbortSignal, body: string | FormData | undefined, requestIndex: number): Request => {
    if (this._gatewayUrl) {
      Log.debug(`Using ${this._gatewayUrl} to proxy ${method} request (${requestIndex}) to ${url}...`);
      headers["X-Target"] = url.toString();
      url = new URL(this._gatewayUrl);
    }
    
    const request = new Request(url, {
      method: method,
      headers:headers,
      signal: signal,
      body: body
    });
    
    return request;
  };
  private static _gatewayUrl: string | null = null;
  private static _requestCounter = 0;
  
  public static get createRequest(): (url: URL, method: string, headers: Record<string, string>, signal: AbortSignal, body: string | FormData | undefined, requestIndex: number) => Request { return HttpClient._createRequest; }
  public static set createRequest(value: (url: URL, method: string, headers: Record<string, string>, signal: AbortSignal, body: string | FormData | undefined, requestIndex: number) => Request) { HttpClient._createRequest = value; }
  public static get gatewayUrl(): string | null { return this._gatewayUrl; }
  public static set gatewayUrl(value: string | null) { this._gatewayUrl = value; }

  private _apiKey: string = null;
  private _attendeeId: string = null;
  private _baseUrl: string = null;
  private _clusterId: string = null;
  private _deviceId: string = null;
  private _maxRetries = 2;
  private _meetingId: string = null;
  private _requestTimeout: number | null = 30000;
  private _tokenFactory: () => Promise<HttpToken> = null;
  private _token: string = null;

  public get baseUrl(): string { return this._baseUrl; }
  public get clusterId(): string { return this._clusterId; }
  public get maxRetries(): number { return this._maxRetries; }
  public set maxRetries(value: number) { this._maxRetries = value; }
  public get requestTimeout(): number | null { return this._requestTimeout; }
  public set requestTimeout(value: number | null) { this._requestTimeout = value; }

  public static sanitizeBaseUrl(baseUrl: string): string {
    while (baseUrl.endsWith("/")) baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    return baseUrl;
  }

  public static withApiKey(apiKey: string, baseUrl: string) {
    Guard.isNotNullOrUndefined(apiKey, "apiKey");
    Guard.isNotNullOrUndefined(baseUrl, "baseUrl");
    const http = new HttpClient();
    http._apiKey = apiKey;
    http._baseUrl = this.sanitizeBaseUrl(baseUrl);
    return http;
  }

  public static withTokenFactory(tokenFactory: () => Promise<HttpToken>) {
    Guard.isNotNullOrUndefined(tokenFactory, "tokenFactory");
    const http = new HttpClient();
    http._tokenFactory = tokenFactory;
    return http;
  }

  private async tryUpdateToken(): Promise<boolean> {
    if (!this._tokenFactory) return false;
    const token = await this._tokenFactory();
    if (this._baseUrl == token.baseUrl && this._token == token.value) return false;
    this._token = token.value;
    this._baseUrl = HttpClient.sanitizeBaseUrl(token.baseUrl);
    const tokenPayload = Utility.getTokenPayload(this._token);
    if (tokenPayload) {
      this._attendeeId = tokenPayload.attendeeId;
      this._clusterId = tokenPayload.clusterId;
      this._deviceId = tokenPayload.deviceId;
      this._meetingId = tokenPayload.meetingId;
    }
    return true;
  }

  private async send(method: HttpMethod, path: string, requestBody: any, requestIndex: number, abortSignal?: AbortSignal, blob?: boolean, retryCounter = 0): Promise<any> {
    await this.tryUpdateToken();

    const isForm = requestBody instanceof FormData;

    // initialize abort controller
    const abortController = new AbortController();
    if (abortSignal) abortSignal.addEventListener("abort", () => {
      abortController.abort(abortSignal.reason);
    });
    if (abortSignal?.aborted) abortController.abort();
    const requestTimeout = this._requestTimeout;
    const requestTimeoutId = Utility.isNullOrUndefined(requestTimeout) ? 0 : setTimeout(() => {
      abortController.abort(`${method} request (${requestIndex}) timed out after ${requestTimeout.toLocaleString()}ms.`);
    }, requestTimeout);

    // initialize URL
    const baseUrl = new URL(this._baseUrl);
    const requestPath = baseUrl.pathname === "/" ? path : `${baseUrl.pathname}/${path}`;
    const url = new URL(requestPath, baseUrl.origin);

    if (this._attendeeId) url.searchParams.append("aid", this._attendeeId);
    if (this._deviceId) url.searchParams.append("did", this._deviceId);
    if (this._meetingId) url.searchParams.append("mid", this._meetingId);

    // initialize headers
    const headers: HeadersInit = {};
    if (!blob) headers["Accept"] = "application/json";
    if (requestBody && !isForm) headers["Content-Type"] = "application/json";
    if (this._apiKey) headers["X-API-Key"] = this._apiKey;
    if (this._token) headers["Authorization"] = `Bearer ${this._token}`;
    
    // initialize request
    const request = HttpClient.createRequest(url, method, headers, abortController.signal, requestBody && !isForm ? JSON.stringify(requestBody) : (isForm ? requestBody : undefined), requestIndex);
    Log.debug(`Sending ${request.method} request (${requestIndex}) to ${request.url}...`);

    let response: Response;
    const performanceStart = performance.now();
    try {
      response = await fetch(request);
    } catch (error: any) {

      // clear timeout
      if (requestTimeoutId) clearTimeout(requestTimeoutId);

      // handle abort signal
      if (abortController.signal.aborted) throw new HttpRequestAbortedError(abortController.signal.reason, request);

      // handle retry exhaustion
      if (retryCounter >= this.maxRetries) throw error;

      // handle retry
      const delay = 200 * Math.pow(2, retryCounter);
      Log.warn(`Resending ${request.method} request (${requestIndex}) to ${request.url} after ${delay.toLocaleString()}ms.`, error);
      await Utility.delay(delay);
      return await this.send(method, path, requestBody, requestIndex, abortSignal, blob, retryCounter + 1);
    }
    
    Log.debug(`Received ${request.method} response (${requestIndex}) from ${request.url} with status code ${response.status}. [Duration: ${Math.round(performance.now() - performanceStart).toLocaleString()}]`);

    // clear timeout
    if (requestTimeoutId) clearTimeout(requestTimeoutId);

    // handle blob
    if (blob && response.ok) return await response.blob();

    // get response
    const responseText = await response.text();

    // handle empty response
    if (!responseText) {
      if (response.ok) return null;
      throw new HttpError(`${response.status} ${response.statusText}`, request, response);
    }

    // parse response
    const apiResponse = Utility.parseJson(responseText);
    if (!apiResponse.errors) return apiResponse;

    // handle errors
    const errorKeys = Object.keys(apiResponse.errors);
    if (errorKeys.length > 0) {
      if (response.status == 202) throw new AcceptedError(apiResponse.errors[errorKeys[0]][0]);
      if (response.status == 401) throw new UnauthorizedError(errorKeys[0], apiResponse.errors[errorKeys[0]][0]);
      if (response.status == 500) throw new InternalServerError(errorKeys[0], apiResponse.errors[errorKeys[0]][0]);
      if (response.status == 503) throw new ServiceUnavailableError(errorKeys[0], apiResponse.errors[errorKeys[0]][0]);
      if (response.status == 400 || response.ok) throw new BadRequestError(errorKeys.map((errorKey) => new BadRequestField(errorKey, apiResponse.errors[errorKey])));
    }
    throw new UnknownError(`${response.status} ${JSON.stringify(apiResponse.errors)}`);
  }

  public delete(path: string, abortSignal?: AbortSignal): Promise<any> {
    return this.send("DELETE", path, null, HttpClient._requestCounter++, abortSignal);
  }

  public get(path: string, abortSignal?: AbortSignal): Promise<any> {
    return this.send("GET", path, null, HttpClient._requestCounter++, abortSignal);
  }

  public getBlob(path: string, abortSignal?: AbortSignal): Promise<Blob> {
    return this.send("GET", path, null, HttpClient._requestCounter++, abortSignal, true);
  }

  public patch(path: string, request: any, abortSignal?: AbortSignal): Promise<any> {
    return this.send("PATCH", path, request, HttpClient._requestCounter++, abortSignal);
  }

  public post(path: string, request: any, abortSignal?: AbortSignal): Promise<any> {
    return this.send("POST", path, request, HttpClient._requestCounter++, abortSignal);
  }

  public postForm(path: string, formData: FormData, abortSignal?: AbortSignal): Promise<any> {
    return this.send("POST", path, formData, HttpClient._requestCounter++, abortSignal);
  }

  public put(path: string, request: any, abortSignal?: AbortSignal): Promise<any> {
    return this.send("PUT", path, request, HttpClient._requestCounter++, abortSignal);
  }
}