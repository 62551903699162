import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import LocalMedia from "./LocalMedia";
import LocalVideoTrackEvent from "./models/LocalVideoTrackEvent";
import LocalVideoTrackStateChangeEvent from "./models/LocalVideoTrackStateChangeEvent";
import LocalVideoTrackState from "./models/LocalVideoTrackState";
import LocalVideoTrackStateMachine from "./LocalVideoTrackStateMachine";
import Log from "../logging/Log";
import Reactive from "../core/Reactive";
import Size from "../core/Size";
import VideoTrack from "./VideoTrack";
import VideoTrackEvent from "./models/VideoTrackEvent";

export default abstract class LocalVideoTrack extends VideoTrack {
  private readonly _onEnded: () => void;
  private readonly _onPaused: () => void;
  private readonly _onResumed: () => void;
  private readonly _ended = new EventOwner<LocalVideoTrackEvent>();
  private readonly _paused = new EventOwner<LocalVideoTrackEvent>();
  private readonly _resumed = new EventOwner<LocalVideoTrackEvent>();
  private readonly _stateChanged: EventOwnerAsync<LocalVideoTrackStateChangeEvent> = new EventOwnerAsync<LocalVideoTrackStateChangeEvent>();
  private readonly _stateEvents = new Map<LocalVideoTrackState, EventOwnerAsync<LocalVideoTrackStateChangeEvent>>();
  private readonly _stateMachine = new LocalVideoTrackStateMachine();
  private readonly _streamBound = new EventOwnerAsync<VideoTrackEvent>();
  private readonly _streamUnbound = new EventOwner<VideoTrackEvent>();

  private _fallbackReason: string | null;
  private _isInFallbackMode: boolean = false;
  private _isReplacingStream: boolean = false;
  private _media: LocalMedia = null;
  private _requestedFrameRate: number = null;
  private _requestedFrameSize: Size = null;
  private _stream: MediaStreamTrack = null;
  
  /** @internal */
  public set media(value: LocalMedia) { this._media = value; }

  public get bitrateMax(): number { return this._media?.connection?.videoBitrateMax; }
  public get fallbackReason(): string { return this._fallbackReason; }
  public get isDisabled(): boolean { return false; }
  public get isInFallbackMode(): boolean { return this._isInFallbackMode; }
  public get isRemote(): boolean { return false; }
  public get isStarted(): boolean { return this.state == "started"; }
  public get isStarting(): boolean { return this.state == "starting"; }
  public get isStopped(): boolean { return this.state == "stopped" || this.state == "new"; }
  public get isStopping(): boolean { return this.state == "stopping"; }
  public get media(): LocalMedia { return this._media; }
  public get pixelCountMax(): number { return this._media?.connection?.videoPixelCountMax; }
  public get requestedFrameRate(): number { return this._requestedFrameRate; }
  public get requestedFrameSize(): Size { return this._requestedFrameSize; }
  public get spatialLayerIndex(): number { return 0; }
  public get state(): LocalVideoTrackState { return this._stateMachine.state; }
  public get stream(): MediaStreamTrack { return this._stream; }
  public get temporalLayerIndex(): number { return 0; }

  /** @event */
  public get ended(): EventOwner<LocalVideoTrackEvent> { return this._ended; }
  /** @event */
  public get paused(): EventOwner<LocalVideoTrackEvent> { return this._paused; }
  /** @event */
  public get resumed(): EventOwner<LocalVideoTrackEvent> { return this._resumed; }
  /** @event */
  public get started(): EventOwnerAsync<LocalVideoTrackStateChangeEvent> { return this._stateEvents.get("started"); }
  /** @event */
  public get starting(): EventOwnerAsync<LocalVideoTrackStateChangeEvent> { return this._stateEvents.get("starting"); }
  /** @event */
  public get stateChanged(): EventOwnerAsync<LocalVideoTrackStateChangeEvent> { return this._stateChanged; }
  /** @event */
  public get stopped(): EventOwnerAsync<LocalVideoTrackStateChangeEvent> { return this._stateEvents.get("stopped"); }
  /** @event */
  public get stopping(): EventOwnerAsync<LocalVideoTrackStateChangeEvent> { return this._stateEvents.get("stopping"); }
  /** @event */
  public get streamBound(): EventOwnerAsync<VideoTrackEvent> { return this._streamBound; }
  /** @event */
  public get streamUnbound(): EventOwner<VideoTrackEvent> { return this._streamUnbound; }

  public constructor() {
    super();
    this._onEnded = this.onEnded.bind(Reactive.wrap(this));
    this._onPaused = this.onPaused.bind(Reactive.wrap(this));
    this._onResumed = this.onResumed.bind(Reactive.wrap(this));

    this._stateEvents.set("started", new EventOwnerAsync<LocalVideoTrackStateChangeEvent>());
    this._stateEvents.set("starting", new EventOwnerAsync<LocalVideoTrackStateChangeEvent>());
    this._stateEvents.set("stopped", new EventOwnerAsync<LocalVideoTrackStateChangeEvent>());
    this._stateEvents.set("stopping", new EventOwnerAsync<LocalVideoTrackStateChangeEvent>());
  }

  private async bindStream(stream: MediaStreamTrack): Promise<void> {
    if (!stream) return;
    if (this._stream == stream) return;
    if (this._stream) this.unbindStream();
    this.prepareStream(stream);
    this._stream = stream;
    this._stream.addEventListener("ended", this._onEnded);
    this._stream.addEventListener("mute", this._onPaused);
    this._stream.addEventListener("unmute", this._onResumed);
    this._stream.enabled = !this.isMuted;
    await this._streamBound.dispatch({
      track: this
    });
  }

  private async setState(state: LocalVideoTrackState): Promise<void> {
    const previousState = this._stateMachine.state;
    this._stateMachine.setState(state);
    const e = <LocalVideoTrackStateChangeEvent>{
      videoTrack: this,
      previousState: previousState,
      state: state,
    };
    await this._stateEvents.get(state).dispatch(e);
    await this._stateChanged.dispatch(e);
  }

  private unbindStream(): void {
    if (!this._stream) return;
    this._stream.stop();
    this._stream.removeEventListener("ended", this._onEnded);
    this._stream.removeEventListener("mute", this._onPaused);
    this._stream.removeEventListener("unmute", this._onResumed);
    this._stream = null;
    this._streamUnbound.dispatch({
      track: this
    });
  }

  protected onEnded(): void { 
    Log.warn(`Local ${this.media.type} video track has ended.`);
    this._ended.dispatch({
      track: this
    });
  }

  protected onPaused(): void {
    Log.debug(`Local ${this.media.type} video track has paused.`);
    this._paused.dispatch({
      track: this
    });
  }

  protected onResumed(): void {
    Log.debug(`Local ${this.media.type} video track has resumed.`);
    this._resumed.dispatch({
      track: this
    });
  }

  protected async onStarted(): Promise<void> { }
  
  protected async onStarting(): Promise<void> { }

  protected async onStopped(): Promise<void> { }

  protected async onStopping(): Promise<void> { }

  protected prepareStream(stream: MediaStreamTrack): void { }

  protected async replaceStream(): Promise<void> {
    if (this._isReplacingStream) {
      Log.warn('Ignoring request to replace video stream as as a previous request is still in progress.');
      return;
    };
    if (!this._media?.stream) return;
    
    this._isReplacingStream = true;
    
    try {
      if (this._stream) {
        this._media.stream.removeTrack(this._stream);
        this.unbindStream();
      }
      const mediaStream = await this._media.getStreamInternal({ video: this.getConstraints() }, this._media.fallbackIfDeviceNotAvailable);
      const [trackStream] = mediaStream.getVideoTracks();
      this._media.stream.addTrack(trackStream);
      await this.bindStream(trackStream);
    } finally {
      this._isReplacingStream = false;
    }    
  }

  /** @internal */
  public clearFallback() {
    this._isInFallbackMode = false;
    this._fallbackReason = null;
  }
  
  /**
   * For testing only.
   */
  /** @internal */
  public end(): void {
    this.stream?.stop();
    this.stream?.dispatchEvent(new Event("ended"));
  }

  /**
   * For testing only.
   */
  /** @internal */
  public pause(): void {
    this.stream?.dispatchEvent(new Event("mute"));
  }

  /**
   * For testing only.
   */
  /** @internal */
  public resume(): void {
    this.stream?.dispatchEvent(new Event("unmute"));
  }

  /* @internal */
  public setFallback(errorName: string) {
    this._isInFallbackMode = true;
    this._fallbackReason = errorName;
  }

  /** @internal */
  public async startInternal(stream: MediaStreamTrack): Promise<void> {
    if (this.state == "started") return;
    try {
      await this.setState("starting");
      await this.onStarting();
      await this.bindStream(stream);
      await this.onStarted();
      await this.setState("started");
    } catch (error) {
      await this.setState("stopped");
      await this.onStopped();
      throw error;
    }
  }

  /** @internal */
  public async stopInternal(): Promise<void> {
    if (this.state == "new") await this.setState("stopped");
    if (this.state == "stopped") return;
    try {
      await this.setState("stopping");
      await this.onStopping();
      this.unbindStream();
    } finally {
      await this.setState("stopped");
      await this.onStopped();
    }
  }

  public async applyContraints(constraints?: MediaTrackConstraints) {
    if (!this._stream) return;
    try {
      const constraintsToApply = constraints ?? this.getConstraints();
      await this.stream.applyConstraints(constraintsToApply);
    } catch (error: any) {
      Log.warn('Error when applying constraints to video track. Trying with min constraints...', error);
      try {
        await this.stream?.applyConstraints(this.getMinConstraints());
      } catch (minError: any) {
        Log.warn('Error when applying min constraints to video track.', error);
      }
    }
  }
  
  public getConstraints(isDisplay?: boolean): MediaTrackConstraints {
    const room = this._media.room;
    var constraints = <MediaTrackConstraints>{ };
    // for browser compatibility, only use ideal for framerate. Setting max value can cause issues in Firefox
    if (room) {
      if (isDisplay) {
        constraints.frameRate = { ideal: room.maxVideoFramerateDisplay };
        constraints.width = { ideal: room.maxVideoWidthDisplay };
        constraints.height = { ideal: room.maxVideoHeightDisplay };
      }
      else {
        constraints.width = { max: room.maxVideoWidthUser };
        constraints.height = { max: room.maxVideoHeightUser };
      }
    }

    // allow overrides
    if (this._requestedFrameRate) {
      constraints.frameRate = { ideal: this._requestedFrameRate };
    }
    if (this._requestedFrameSize && !isDisplay) {
      constraints.width = { ideal: this._requestedFrameSize.width, max: room?.maxVideoWidthUser ?? undefined };
      constraints.height = { ideal: this._requestedFrameSize.height, max: room?.maxVideoHeightUser ?? undefined };
    }

    return constraints;
  }
  
  public getMinConstraints(isDisplay?: boolean): MediaTrackConstraints {
    const room = this._media.room;
    const constraints = <MediaTrackConstraints>{ };
    if (room) {
      if (isDisplay) {
        constraints.frameRate = { ideal: room.minVideoFramerateDisplay };
        constraints.width = { ideal: room.minVideoWidthDisplay };
        constraints.height = { ideal: room.minVideoHeightDisplay };
      }
      else {
        constraints.frameRate = { ideal: room.minVideoFramerateUser };
        constraints.width = { ideal: room.minVideoWidthUser };
        constraints.height = { ideal: room.minVideoHeightUser };
      }
    }
    else {
      constraints.frameRate = { ideal: 5 };
      constraints.width = { ideal: 240 };
      constraints.height = { ideal: 320 };
    }
    return constraints;
  }

  public async reload(): Promise<void> {
    return await this.replaceStream();
  }

  public async setFrameRate(frameRate?: number): Promise<void> {
    if (this._requestedFrameRate == frameRate) return;
    this._requestedFrameRate = frameRate ?? null;
    await this.applyContraints();
  }

  public async setFrameSize(frameSize?: Size): Promise<void> {
    if (this._requestedFrameSize?.width == frameSize?.width && this._requestedFrameSize?.height == frameSize?.height) return;
    this._requestedFrameSize = frameSize ?? null;
    await this.applyContraints()
  }

  public start() {
    return this._media.startVideo();
  }

  public async stop() {
    return this._media.stopVideo();
  }
}
