import ClientModel from "./models/Client";
import ConnectionBase from "../Connection";
import ConnectionInit from "./models/ConnectionInit";
import Constraints from "./models/Constraints";
import Guard from "../core/Guard";
import PromiseCompletionSource from "../core/PromiseCompletionSource";
import Reactive from "../core/Reactive";

import DispatchQueue from "../core/DispatchQueue";
import Log from "../logging/Log";
import EventLogger from "../event/Logger";
import EventOwnerAsync from "../core/EventOwnerAsync";

import Message from "./models/Message";

import LocalMedia from "../media/LocalMedia";
import LocalMediaOptions from "../models/LocalMediaOptions";
import LocalTrackPriority from "../models/LocalTrackPriority";


import Track from "../models/Track";
import TrackType from "../media/models/TrackType";
import StepType from "./models/StepType";
import MediaEvent from "./models/MediaEvent";
import MediaType from "../media/models/MediaType";

import StatisticConnection from "../models/StatisticConnection";
import StatisticAudio from "../models/StatisticAudio";
import StatisticVideo from "../models/StatisticVideo";

import Bitrate from "../core/Bitrate";
import Framerate from "../core/Framerate";

import VideoUtility from "../core/VideoUtility";
import {
  VideoOriginFrameRateDisplayMin,
  VideoOriginPixelCountDisplayMin,
  VideoOriginJitterDisplayLow,
  VideoOriginJitterDisplayMedium,
  VideoOriginJitterDisplayHigh,
  VideoOriginPacketLossDisplayLow,
  VideoOriginPacketLossDisplayMedium,
  VideoOriginPacketLossDisplayHigh,
  AudioOriginJitterThresholdDisplayLow,
  AudioOriginJitterThresholdDisplayMedium,
  AudioOriginJitterThresholdDisplayHigh,
  AudioOriginPacketLossThresholdDisplayLow,
  AudioOriginPacketLossThresholdDisplayMedium,
  AudioOriginPacketLossThresholdDisplayHigh,
  VideoOriginFrameRateUserMin,
  VideoOriginPixelCountUserMin,
  VideoOriginJitterUserLow,
  VideoOriginJitterUserMedium,
  VideoOriginJitterUserHigh,
  VideoOriginPacketLossUserLow,
  VideoOriginPacketLossUserMedium,
  VideoOriginPacketLosUserHigh,
  AudioOriginJitterThresholdUserLow,
  AudioOriginJitterThresholdUserMedium,
  AudioOriginJitterThresholdUserHigh,
  AudioOriginPacketLossThresholUserLow,
  AudioOriginPacketLossThresholdUserMedium,
  AudioOriginPacketLossThresholdUserHigh
} from "./models/TenantSettings";


const qualityLimitationReasons: Map<String, number> = new Map([["none", 1000], ["bandwidth", 2000], ["cpu", 3000], ["other", 4000]]);
const qualityLimitationReasonNone = 1000;
const qualityLimitationReasonUs = 100; // SDK is making the constraint adjustment
const qualityLimitationReasonDecrease = 10;
const qualityLimitationReasonIncrease = 20
const qualityLimitationReasonBitrate = 1;
const qualityLimitationReasonFrameRate = 3;
const qualityLimitationReasonResolution = 5;

// Default

const defaultMinBitrate = 200;
const defaultMinFrameRateUser = 12;
const defaultMinFrameRateDisplay = 3;

const trackTypes: TrackType[] = ["audio", "video"];
/*
* Audio impairment default statuses. 
*/
export enum AudioHealthIssues {
  JITTERHIGH = "Jitter - High",
  JITTERMEDIUM = "Jitter - Medium",
  JITTERLOW = "Jitter - Low",
  PACKETLOSSHIGH = "Packet Loss - High",
  PACKETLOSSMEDIUM = "Packet Loss - Medium",
  PACKETLOSSLOW = "Packet Loss - Low",
  ROUNDTRIPTIMEHIGH = "Round Trip Time - High",
  ROUNDTRIPTIMEMEDIUM = "Round Trip Time - Medium",
  ROUNDTRIPTIMELOW = "Round Trip Time - Low"
}
export default class Connection extends ConnectionBase<Message> {
  private readonly _audioLevelInterval?: number;
  private readonly _client: ClientModel;
  private readonly _mediaChannel: RTCDataChannel;
  private readonly _mediaNotificationQueue = new DispatchQueue();
  private readonly _mediaOptions: LocalMediaOptions;
  private readonly _mediaRejected = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaReplaced = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaType: MediaType;
  private readonly _onAudioTrackStreamBound: () => Promise<void>;
  private readonly _onAudioTrackStreamUnbound: () => void;
  private readonly _onMediaChannelClose: () => void;
  private readonly _onMediaChannelClosing: () => void;
  private readonly _onMediaChannelError: (ev: any) => void;
  private readonly _onMediaChannelMessage: (ev: MessageEvent<any>) => any;
  private readonly _onMediaChannelOpen: () => void;
  private readonly _onMediaStateChanged: () => Promise<void>;
  private readonly _onVideoTrackFrameSizeChanged: () => void;
  private readonly _onVideoTrackStreamBound: () => Promise<void>;
  private readonly _onVideoTrackStreamUnbound: () => void;
  private readonly _redAudioEnabledOrigin: boolean;
  private readonly _replicationCount?: number;

  private readonly _transceivers = new Map<TrackType, Track>();

  //TODO: magic numbers
  private _jitterHigh = 120;
  private _jitterMedium = 79;
  private _jitterLow = 30;
  private _packetLossHigh = .28;
  private _packetLossMedium = .08;
  private _packetLossLow = .03;

  // Audio impairment default thresholds.
  // - Jitter
  private _audioJitterThresholdHigh: number = 120;
  private _audioJitterThresholdMedium: number = 79;
  private _audioJitterThresholdLow: number = 30;
  // - Packet Loss
  private _audioPacketLossThresholdHigh: number = 0.28;
  private _audioPacketLossThresholdMedium: number = 0.08;
  private _audioPacketLossThresholdLow: number = 0.03;

  private _answerUpdated: PromiseCompletionSource<Message>;
  private _bitrateIncreases = 0;
  private _bitrateSteady = 0;
  private _bitrateThrottle = 1.0;
  private _encodingEnabled = true;
  private _lastStepType: StepType = "pixelCount";
  private _largePacketLossEvent = false;
  private _media: LocalMedia = null;
  private _mediaChannelBytesReceived = 0;
  private _mediaChannelBytesSent = 0;
  
  private _customBandwidthAdaptationEnabled = false;
  private _minBitrate = defaultMinBitrate;
  private _minAudioBitrate = 12;
  private _minFrameRate = defaultMinFrameRateUser;
  private _minHeight = 240;
  private _minWidth = 320;
  private _maxBitrate = 2200;
  private _maxAudioBitrate = 96;
  private _maxAudioJitterDelay = 40;
  private _maxFrameRate = 25;
  private _maxHeight = 1080;
  private _maxVideoJitterDelay = 200;
  private _maxWidth = 1920;
  private _minPixelCount = 76800;
  private _maxPixelCount = 2073600;
  private _priorityAudio: LocalTrackPriority = "high";
  private _priorityVideo: LocalTrackPriority = "high";
  private _qualityLimitationReason = 0;
  private _rampedUp = false;
  private _rampUpSeconds = 5;
  private _currentRampUpSeconds = 0;
  private _recoveryTime = 0;
  private _serverConstraints: Constraints = null;
  private _clientConstraints: Constraints = null;
  private _estimatedConstraints: Constraints = null;
  private _videoCanRecover = true;
  private _healthyChecks = 0;
  private _unhealthyChecks = 0;

  private _statsBatchAudio: Array<StatisticAudio> = [];
  private _statsBatchVideo: Array<StatisticVideo> = [];
  //TODO: from setting, or at least a shared constant
  private _statsPollingInterval = 5;  //how often to save the stats
  private _statsBatchSize = 6; //how many saves before sending to server
  private _currentStatCount = 0;
  private _lastPairState: string = null;

  //TODO: these should belong on an aggregate Track object instead of in arrays like all the other variables. It'll just be wrong sometimes for now
  private _bitrateAudio = new Bitrate();
  private _bitrateVideo = new Bitrate();
  private _framerateVideo = new Framerate();

  public get audioBitrateMax(): number { return this._clientConstraints?.audioBitrateMax; }
  public get media(): LocalMedia { return this._media; }
  public get mediaRejected(): EventOwnerAsync<MediaEvent> { return this._mediaRejected; }
  public get mediaReplaced(): EventOwnerAsync<MediaEvent> { return this._mediaReplaced; }
  public get mediaType(): MediaType { return this._mediaType; }
  public get priorityAudio(): LocalTrackPriority { return this._priorityAudio; }
  public get priorityVideo(): LocalTrackPriority { return this._priorityVideo; }
  public get videoBitrateMax(): number { return this._clientConstraints?.videoBitrateMax; }
  public get videoPixelCountMax(): number { return this._clientConstraints?.videoPixelCountMax ?? this._maxPixelCount; }

  public get videoFramerateMin(): number { return this._minFrameRate; }
  public get videoHeightMin(): number { return this._minHeight; }
  public get videoWidthMin(): number { return this._minWidth; }
  public get videoFramerateMax(): number { return this._maxFrameRate; }
  public get videoHeightMax(): number { return this._maxHeight; }
  public get videoWidthMax(): number { return this._maxWidth; }

  public constructor(init: ConnectionInit) {
    super({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "OriginConnection", init.attendeeId, init.meetingId, init.clusterId),
      iceRestartEnabled: init.iceRestartEnabled,
      meetingId: init.meetingId,
      turnRequired: init.turnRequired,
      turnSession: init.turnSession,
      type: `Origin (${init.mediaType})`,
    });
    this._audioLevelInterval = init.audioLevelInterval;
    this._client = init.client;
    this._mediaOptions = init.mediaOptions;
    this._mediaOptions.webRtcDegradationPreferenceEnabled ??= false;
    this._mediaType = init.mediaType;
    this._redAudioEnabledOrigin = init.redAudioEnabled;
    this._replicationCount = init.replicationCount;
    this._statsBatchAudio = [];
    this._statsBatchVideo = [];
    this._bitrateAudio = new Bitrate();
    this._bitrateVideo = new Bitrate();
    this._framerateVideo = new Framerate();

    if (init.room) {
      const room = init.room;
      if (this._mediaType === "display") {
        if (room.minVideoBitrate) this._minBitrate = room.minVideoBitrate;
        if (room.minVideoFramerateDisplay) this._minFrameRate = room.minVideoFramerateDisplay;
        if (room.minVideoPixelCountDisplay) this._minPixelCount = room.minVideoPixelCountDisplay;
        if (room.maxAudioDisplay) this._maxAudioBitrate = room.maxAudioDisplay;
        if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      } else if (this._mediaType === "user") {
        if (room.minVideoBitrate) this._minBitrate = room.minVideoBitrate;
        if (room.minVideoFramerateUser) this._minFrameRate = room.minVideoFramerateUser;
        if (room.minVideoPixelCountUser) this._minPixelCount = room.minVideoPixelCountUser;
        if (room.minVideoHeightUser) this._minHeight = room.minVideoHeightUser;
        if (room.minVideoWidthUser) this._minHeight = room.minVideoWidthUser;
        if (room.maxVideoFramerateUser) this._maxFrameRate = room.maxVideoFramerateUser;
        if (room.maxVideoHeightUser) this._maxHeight = room.maxVideoHeightUser;
        if (room.maxVideoWidthUser) this._maxHeight = room.maxVideoWidthUser;
        if (room.maxAudioBitrate) this._maxAudioBitrate = room.maxAudioBitrate;
        if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      }
      if (room.maxAudioJitterDelay) this._maxAudioJitterDelay = room.maxAudioJitterDelay;
      if (room.maxVideoBitrate) this._maxBitrate = room.maxVideoBitrate;
      if (room.maxVideoJitterDelay) this._maxVideoJitterDelay = room.maxVideoJitterDelay;
    }

    //TODO: change to eventLogger
    if (init.tenantSettings && init.tenantSettings.length > 0) {
      const settings = init.tenantSettings;
      settings.forEach(setting => {
        try {
          if (this._mediaType === "display") {
            switch (setting.settingName) {
              case VideoOriginFrameRateDisplayMin:
                if (this._minFrameRate == 0) {
                  this._minFrameRate = Number.parseFloat(setting.settingValue);
                  Log.debug(`Set minFrametRate from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginPixelCountDisplayMin:
                if (this._minPixelCount == 0) {
                  this._minPixelCount = Number.parseInt(setting.settingValue);
                  Log.debug(`Set minPixelCount from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginJitterDisplayLow: {
                this._jitterLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterDisplayMedium: {
                this._jitterMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterDisplayHigh: {
                this._jitterHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayLow: {
                this._packetLossLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayMedium: {
                this._packetLossMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayHigh: {
                this._packetLossHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // Audio Impairment Thresholds - (Display)
              case AudioOriginJitterThresholdDisplayLow: {
                this._audioJitterThresholdLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdDisplayMedium: {
                this._audioJitterThresholdMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdDisplayHigh: {
                this._audioJitterThresholdHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdDisplayLow: {
                this._audioPacketLossThresholdLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdDisplayMedium: {
                this._audioPacketLossThresholdMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdDisplayHigh: {
                this._audioPacketLossThresholdHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // END Audio Settings
            }
          } else if (this._mediaType === "user") {
            switch (setting.settingName) {
              case VideoOriginFrameRateUserMin:
                if (this._minFrameRate == 0) {
                  this._minFrameRate = Number.parseFloat(setting.settingValue);
                  Log.debug(`Set minFrametRate from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginPixelCountUserMin:
                if (this._minPixelCount == 0) {
                  this._minPixelCount = Number.parseInt(setting.settingValue);
                  Log.debug(`Set minPixelCount from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginJitterUserLow: {
                this._jitterLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterUserMedium: {
                this._jitterMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterUserHigh: {
                this._jitterHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossUserLow: {
                this._packetLossLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossUserMedium: {
                this._packetLossMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLosUserHigh: {
                this._packetLossHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // Audio Impairment Thresholds - Settings (User)
              case AudioOriginJitterThresholdUserLow: {
                this._audioJitterThresholdLow = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdUserMedium: {
                this._audioJitterThresholdMedium = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginJitterThresholdUserHigh: {
                this._audioJitterThresholdHigh = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholUserLow: {
                this._audioPacketLossThresholdLow = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdUserMedium: {
                this._audioPacketLossThresholdMedium = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case AudioOriginPacketLossThresholdUserHigh: {
                this._audioPacketLossThresholdHigh = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh (audio) from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              // END Audio Settings
            }
          }

          switch (setting.settingName) {
            case "ORIGIN:BITRATE:MIN":
              if (this._minBitrate == 0) {
                this._minBitrate = Number.parseInt(setting.settingValue);
                Log.debug(`Set minBitrate from TenantSetting: Value=${setting.settingValue}`);
              }
            case "ORIGIN:STATISTIC:BATCHSIZE":
              this._statsBatchSize = Number.parseInt(setting.settingValue);
              Log.debug(`Set statsBatchSize from TenantSetting: Value=${setting.settingValue}`);
              break;
            case "ORIGIN:RAMPUP:SECONDS":
              this._rampUpSeconds = Number.parseInt(setting.settingValue);
              Log.debug(`Set rampUpSeconds from TenantSetting: Value=${setting.settingValue}`);
              break;
          }
        } catch (err: any) {
          //TODO: change to eventLogger
          Log.error(`Error parsing TenantSetting: ${setting.settingName}=${setting.settingValue} Type=${setting.settingType}`, err);
        }
      });
    }

    // Defaults if values are not set by settings

    if (this._minBitrate == 0) this._minBitrate = defaultMinBitrate;
    if (this._minFrameRate == 0) this._minFrameRate = this._mediaType == "display" ? defaultMinFrameRateDisplay : defaultMinFrameRateUser;
    if (this._minPixelCount == 0) this._minPixelCount = this._mediaType == "display" ? 1280 * 720 : this._minHeight * this._minWidth;
    if (this._jitterHigh == 0) this._jitterHigh = 120;
    if (this._jitterMedium == 0) this._jitterMedium = 79;
    if (this._jitterMedium == 0) this._jitterLow = 30;
    if (this._packetLossHigh == 0) this._packetLossHigh = .28;
    if (this._packetLossMedium == 0) this._packetLossMedium = .08;
    if (this._packetLossMedium == 0) this._packetLossLow = .03;

    this._mediaOptions.webRtcDegradationPreferenceEnabled ??= false;

    if (this._mediaOptions.webRtcDegradationPreferenceEnabled) {
      this._customBandwidthAdaptationEnabled = false;
    }

    this._onAudioTrackStreamBound = this.onAudioTrackStreamBound.bind(Reactive.wrap(this));
    this._onAudioTrackStreamUnbound = this.onAudioTrackStreamUnbound.bind(Reactive.wrap(this));
    this._onMediaChannelClose = this.onMediaChannelClose.bind(Reactive.wrap(this));
    this._onMediaChannelClosing = this.onMediaChannelClosing.bind(Reactive.wrap(this));
    this._onMediaChannelError = this.onMediaChannelError.bind(Reactive.wrap(this));
    this._onMediaChannelMessage = this.onMediaChannelMessage.bind(Reactive.wrap(this));
    this._onMediaChannelOpen = this.onMediaChannelOpen.bind(Reactive.wrap(this));
    this._onMediaStateChanged = this.onMediaStateChanged.bind(Reactive.wrap(this));
    this._onVideoTrackFrameSizeChanged = this.onVideoTrackFrameSizeChanged.bind(Reactive.wrap(this));
    this._onVideoTrackStreamBound = this.onVideoTrackStreamBound.bind(Reactive.wrap(this));
    this._onVideoTrackStreamUnbound = this.onVideoTrackStreamUnbound.bind(Reactive.wrap(this));

    this._mediaChannel = this.connection.createDataChannel("media");
    this._mediaChannel.binaryType = "arraybuffer";

    for (const trackType of trackTypes) {
      const trk = new Track();
      trk.status = "enabled";
      trk.mediaType = this._mediaType;
      trk.trackType = trackType;
      trk.index = this._transceivers.size;
      trk.priority = "high";
      trk.spatialLayerIndex = 0;
      trk.temporalLayerIndex = 0;
      trk.statAudio = new StatisticAudio();
      trk.statVideo = new StatisticVideo();
      trk.statPair = new StatisticConnection();
      trk.bitrateAudio = new Bitrate();
      trk.bitrateVideo = new Bitrate();
      trk.framerateVideo = new Framerate();

      const transceiver = this.connection.addTransceiver(trackType, { direction: "inactive" });
      trk.transceiver = transceiver;
      this._transceivers.set(trackType, trk);
      //this._senders.set(trackType, transceiver.sender);
      //this._senderStats.set(trackType, new TrackStats());

      if (trackType === "audio") {
         this.setPreferredAudioCodecsOrigin(transceiver);
      }
    }

    this.attachEventHandlers();
  }

  private getSenderEncodings(parameters: RTCRtpSendParameters): RTCRtpEncodingParameters[] {
    const encodings = parameters.encodings ?? [{}];
    parameters.encodings = encodings;
    return encodings;
  }

  private onAudioTrackStreamBound(): Promise<void> {
    const trackStream = this._media.audioTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("audio", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onAudioTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("audio", null);
      await this.updateTransceiverDirections();
    });
  }

  private onMediaChannelClose(): void {
    const message = `Origin ${this.mediaType} connection media channel has closed.`;
    void this.eventLogger.debug("onMediaChannelClose", message);
  }

  private onMediaChannelClosing(): void {
    const message = `Origin ${this.mediaType} connection media channel is closing.`;
    void this.eventLogger.debug("onMediaChannelClosing", message);
  }

  private onMediaChannelError(ev: RTCErrorEvent): void {
    const errDetail = ev.error?.errorDetail ?? ev.error ?? "";
    const message = `Origin ${this.mediaType} connection media channel has failed. ${errDetail}`.trimEnd();
    void this.eventLogger.debug("onMediaChannelError", message);
  }

  //TODO: in-progess deprecating
  private onMediaChannelMessage(ev: MessageEvent<any>): void {
    //const arrayBuffer = <ArrayBuffer>ev.data;
    //this._mediaChannelBytesReceived += arrayBuffer.byteLength;
    //const buffer = new Uint8Array(arrayBuffer);
    //if (buffer.length == 0) return;
    //const payloadType = buffer[0];
    //if (payloadType == 0) {
    //  if (buffer.length == 1) return;
    //  const audioLevel = buffer[1] / 255;
    //  this._media?.audioTrack?.updateLevel(audioLevel);
    //}
  }

  private onMediaChannelOpen(): void {
    const message = `Origin ${this.mediaType} connection media channel has opened.`;
    void this.eventLogger.debug("onMediaChannelOpen", message);
  }

  private onMediaStateChanged(): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackFrameSizeChanged(): void {
    void this.eventQueue.dispatch(async () => {
      await this.updateSenderParametersVideo();
    });
  }

  private onVideoTrackStreamBound(): Promise<void> {
    const trackStream = this._media.videoTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("video", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("video", null);
      await this.updateTransceiverDirections();
    });
  }

  private async tryReplaceSenderTrack(trackType: TrackType, track: MediaStreamTrack): Promise<boolean> {
    const sender = this.getSender(trackType);
    try {
      if (this.state == "closed") return false;
      if (sender.track == track) return false;
      await sender.replaceTrack(track);
      if (trackType == "video") this.sendNotification({
        type: "frameRateUpdated",
        frameRate: track?.getSettings()?.frameRate ?? null,
      });
      return true;
    } catch (error: any) {
      const trkDetail = track == null ? "true" : "false";
      void this.eventLogger.warning(<Error>error, "tryReplaceSenderTrack", `Could not replace origin ${this._mediaType} ${trackType} sender track (null:${trkDetail}).`);
      return false;
    }
  }

  private trySetTransceiverDirection(trackType: TrackType, direction: RTCRtpTransceiverDirection): boolean {
    const transceiver = this.getTransceiver(trackType);
    try {
      if (this.state == "closed") return false;
      if (transceiver.direction == direction || transceiver.direction == "stopped" || (transceiver as any).stopped /* legacy */) return false;
      transceiver.direction = direction;
      return true;
    } catch (error: any) {
      void this.eventLogger.warning(<Error>error, "trySetTransceiverDirection", `Could not set origin ${this._mediaType} ${trackType} transceiver direction to ${direction}.`);
      return false;
    }
  }

  private async updateConstraints(constraints: Constraints): Promise<void> {
    void this.eventLogger.debug("updateConstraints", `Updating origin ${this._mediaType} constraints...`, null, {
      audioBitrateMax: constraints.audioBitrateMax,
      videoBitrateMax: constraints.videoBitrateMax,
      videoFrameRateMax: constraints.videoFrameRateMax,
      videoPixelCountMax: constraints.videoPixelCountMax,
    });

    if (this._estimatedConstraints == null) {
      this._estimatedConstraints = {
        audioBitrateMax: constraints.audioBitrateMax,
        videoBitrateMax: this._maxBitrate,
        videoFrameRateMax: this._maxFrameRate,
        videoPixelCountMax: this._maxPixelCount
      } as Constraints;
    }
    if (this._clientConstraints == null) {
      this._clientConstraints = {
        audioBitrateMax: constraints.audioBitrateMax,
        videoBitrateMax: this._maxBitrate,
        videoFrameRateMax: this._maxFrameRate,
        videoPixelCountMax: this._maxPixelCount
      } as Constraints;
    }

    if (this._serverConstraints == null) {
      this._serverConstraints = constraints;
    } else {
      Log.debug(`Updating server constraints: ${JSON.stringify(constraints)}`);
      this._serverConstraints.audioBitrateMax = constraints.audioBitrateMax;
      this._serverConstraints.videoBitrateMax = Math.max(this._minBitrate, constraints.videoBitrateMax);
      this._serverConstraints.videoFrameRateMax = Math.max(this._minFrameRate, constraints.videoFrameRateMax);
      this._serverConstraints.videoPixelCountMax = Math.max(this._minPixelCount, constraints.videoPixelCountMax);
    }
    await this.updateSenderParameters();
  }

  private async updateMediaBindings(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  private async updateSenderParametersAudio(): Promise<void> {
    const sender = this._transceivers.get("audio").transceiver.sender;
    const parameters = sender.getParameters();
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {
      if (this._clientConstraints?.audioBitrateMax) {
        const maxBitrate = this._clientConstraints.audioBitrateMax * 1000;
        if (encodings[0].maxBitrate != maxBitrate) {
          //void this.eventLogger.debug("updateSenderParametersAudio", "Setting origin ${this._mediaType} audio max bitrate...", null, {
          //  maxBitrate: maxBitrate,
          //});
          encodings[0].maxBitrate = maxBitrate;
        }
      }
      encodings[0].priority = this._priorityAudio;
      try {
        await sender.setParameters(parameters);
      } catch (error: any) {
        void this.eventLogger.debug(<Error>error, "updateSenderParametersAudio", `Could not set origin ${this._mediaType} audio sender parameters.`);
      }
      Log.debug(`Sender Parameter Update: Enabled=${this._encodingEnabled}, Bitrate=${encodings[0].maxBitrate}, Priority=${encodings[0].priority}`);
    }
  }

  private async updateSenderParametersVideo(): Promise<void> {
    const sender = this._transceivers.get("video").transceiver.sender;
    const parameters = sender.getParameters();

    if (this._mediaOptions.webRtcDegradationPreferenceEnabled) {
      parameters.degradationPreference = this._mediaOptions.degradationPreference;
    }
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {
      if (this._clientConstraints?.videoBitrateMax) {
        const maxBitrate = Math.round(this._clientConstraints.videoBitrateMax * 1000);
        if (encodings[0].maxBitrate != maxBitrate) {
          void this.eventLogger.debug("updateSenderParametersVideo", `Setting origin ${this._mediaType} video max bitrate...`, null, {
           maxBitrate: maxBitrate,
          });
          encodings[0].maxBitrate = maxBitrate;
        }
      }
      if (this._clientConstraints?.videoFrameRateMax) {
        const maxFrameRate = this._clientConstraints.videoFrameRateMax;
        if (encodings[0].maxFramerate != maxFrameRate) {
          void this.eventLogger.debug("updateSenderParametersVideo", `Setting origin ${this._mediaType} video max frame rate...`, null, {
           maxFramerate: maxFrameRate,
          });
          encodings[0].maxFramerate = maxFrameRate;
        }
      }
      if (this._clientConstraints?.videoPixelCountMax) {
        const settings = sender.track?.getSettings();
        if (settings && settings.width && settings.height) {
          const scaleResolutionDownBy = Math.max(1, Math.sqrt(settings.width * settings.height / (this._clientConstraints.videoPixelCountMax)));
          if (encodings[0].scaleResolutionDownBy != scaleResolutionDownBy) {
            void this.eventLogger.debug("updateSenderParametersVideo", `Scaling down origin ${this._mediaType} video resolution...`, null, {
             scaleResolutionDownBy: scaleResolutionDownBy,
             videoWidth: settings.width,
             videoHeight: settings.height,
            });
            encodings[0].scaleResolutionDownBy = scaleResolutionDownBy;
          }
        }
      }

      encodings[0].active = this._encodingEnabled;
      encodings[0].priority = this._priorityVideo;

      try {
        await sender.setParameters(parameters);
      } catch (error: any) {
        void this.eventLogger.debug(<Error>error, "updateSenderParametersVideo", `Could not set origin ${this._mediaType} video sender parameters.`);
      }
      Log.debug(`Sender Parameter Update: Enabled=${this._encodingEnabled}, Bitrate=${encodings[0].maxBitrate}, FrameRate=${encodings[0].maxFramerate}, ResolutionScale=${encodings[0].scaleResolutionDownBy}`);
    }
  }

  private async updateSenderParameters(): Promise<void> {
    await this.updateSenderParametersAudio();
    await this.updateSenderParametersVideo();
  }

  private setPreferredAudioCodecsOrigin(audioTransceiver: RTCRtpTransceiver): void {
    if (!this._supportsSetCodecPreferences || !this._redAudioEnabled) {
      return;
    }
    const {codecs} = RTCRtpSender.getCapabilities('audio');
    const redCodecIndex = codecs.findIndex(c => c.mimeType === 'audio/red');
    if (redCodecIndex == -1) {
      Log.info('audio/red codec not supported');
      return;
    }
    const redCodec = codecs[redCodecIndex];
    this._preferredCodec = redCodec;
    codecs.splice(redCodecIndex, 1);
    codecs.unshift(redCodec);
    const transceiver = audioTransceiver || this.connection.getTransceivers().find(x => x.sender && x.sender.track.kind === "audio");
    transceiver.setCodecPreferences(codecs);
    Log.info(`audio/red codec preference set: ${JSON.stringify(codecs)}`);
  }

  private async updateTransceiverDirections(): Promise<void> {
    let updateOffer = false;
    if (this._media?.audioTrack?.stream) {
      if (this.trySetTransceiverDirection("audio", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("audio", "inactive")) updateOffer = true;
    }
    if (this._media?.videoTrack?.stream) {
      if (this.trySetTransceiverDirection("video", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("video", "inactive")) updateOffer = true;
    }
    if (!updateOffer || this.state == "new" || this.state == "closed") return;
    let offer = (await this.connection.createOffer()).sdp;
    offer = await this.mungeOffer(offer);
    await this.connection.setLocalDescription({
      sdp: offer,
      type: "offer"
    });
    //TODO: use sendRequest once the server honors requests
    this.sendNotification({
      type: "offerUpdated",
      offer: offer,
    });
    this._answerUpdated = new PromiseCompletionSource();
    try {
      let answer = (await this._answerUpdated.promise).answer;
      answer = await this.mungeAnswer(answer);
      await this.connection.setRemoteDescription({
        sdp: answer,
        type: "answer",
      });
      await this.updateSenderParameters();
    } catch (error) { 
      if (this._mediaType == "display") return; // display media rejected
      else Log.error(`Couldn't update transceiver directions. ${error}`);
    }
  }

  /** @internal */
  public getSender(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver.sender;
  }

  public setMaxAudio(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver.sender;
  }

  /** @internal */
  public getTransceiver(trackType: TrackType): RTCRtpTransceiver {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver;
  }

  /** @internal */
  public async pauseInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", null);
    await this.tryReplaceSenderTrack("video", null);
  }

  /** @internal */
  public async resumeInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  protected attachEventHandlers(): void {
    super.attachEventHandlers();
    this._mediaChannel.addEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.addEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.addEventListener("error", this._onMediaChannelError);
    this._mediaChannel.addEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.addEventListener("open", this._onMediaChannelOpen);
  }

  protected detachEventHandlers(): void {
    this._media?.stateChanged.unbind(this._onMediaStateChanged);
    this._media?.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
    this._media?.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
    this._media?.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
    this._media?.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
    this._mediaChannel.removeEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.removeEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.removeEventListener("error", this._onMediaChannelError);
    this._mediaChannel.removeEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.removeEventListener("open", this._onMediaChannelOpen);
    super.detachEventHandlers();
  }

  protected async negotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.negotiate({
      audioLevelInterval: this._audioLevelInterval,
      mediaType: this._mediaType,
      offer: offer,
      replicationCount: this._replicationCount,
    }, abortSignal)).answer;
  }

  protected async onOpened(): Promise<void> {
    await super.onOpened();
    await this.updateMediaBindings();
    await this.updateTransceiverDirections();
    await this.updateSenderParameters();
  }

  protected onTerminated(): void {
    this._answerUpdated?.reject("Connection closed.");
    super.onTerminated();
  }

  protected processNotification(notification: Message): void {
    if (notification.type == "answerUpdated") {
      if (this._answerUpdated) this._answerUpdated.resolve(notification);
    } else if (notification.type == "constraintsUpdated") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this.updateConstraints(notification.constraints);
      });
    } else if (notification.type == "displayMediaRejected") {
      if (this._answerUpdated) this._answerUpdated.reject(new Error("Media rejected."));
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaRejected.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else if (notification.type == "displayMediaReplaced") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaReplaced.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else {
      void this.eventLogger.warning(null, "processNotification", `Unexpected ${notification.type} edge notification.`);
    }
  }

  /*
  * Called by ProcessSenderStats, used to deteremine and update audio bitrate in the event of either a newly healthy network or a impaired network. 
  * Will adjust the quality of your audio both up and down depending on network conditions. 
  */

  private async calculateAudioConstraints(trk: Track): Promise<void> {
    if (!this._customBandwidthAdaptationEnabled) {
      Log.debug('Custom bandwidth adaptation is disabled');
      return;
    }

    if (!this._rampedUp) {
      Log.debug('Waiting for ramp up to complete');
      return;
    }

    const audioIssuesFound: AudioHealthIssues[] = [];

    //TODO: initialize and remove conformance
    // AudioBitrateMax comes from the constraint, which may be null.
    // Max Audio Bitrate is the tenant setting.
    const currentBitrate = this.audioBitrateMax || this._maxAudioBitrate;

    //if (trk.statAudio.Rtp_Jitter > this._maxAudioJitterDelay || trk.statAudio.Track_JitterBufferDelay > this._maxAudioJitterDelay) {
    //  Log.debug("CalculateAudioConstraints - Jitter or JitterBufferDelay value is over the room's maxAudioJitterDelay property.");
    //}

    // Determine if there was a jitter issue.
    // A low value is favourable over a high value.  
    if (trk.statAudio.Rtp_Jitter >= this._audioJitterThresholdHigh) {
      audioIssuesFound.push(AudioHealthIssues.JITTERHIGH);
    } else if (trk.statAudio.Rtp_Jitter >= this._audioJitterThresholdMedium) {
      audioIssuesFound.push(AudioHealthIssues.JITTERMEDIUM);
    } else if (trk.statAudio.Rtp_Jitter >= this._audioJitterThresholdLow) {
      audioIssuesFound.push(AudioHealthIssues.JITTERLOW);
    }

    // Determine if there was a packet loss issue.
    // A low value is favourable over a high value. 
    if (this._media.audioTrack.packetLoss >= this._audioPacketLossThresholdHigh) {
      audioIssuesFound.push(AudioHealthIssues.PACKETLOSSHIGH);
    } else if (this._media.audioTrack.packetLoss  >= this._audioPacketLossThresholdMedium) {
      audioIssuesFound.push(AudioHealthIssues.PACKETLOSSMEDIUM);
    } else if (this._media.audioTrack.packetLoss  >= this._audioPacketLossThresholdLow) {
      audioIssuesFound.push(AudioHealthIssues.PACKETLOSSLOW);
    }

    let availableBitrate = trk.statPair.Pair_AvailableOutgoing;

    // If the available bitrate is not avaialble. 
    if (availableBitrate == null) {
      availableBitrate = currentBitrate;
    }
    // Determine if there was a round trip time issue.
    let newBitrate = currentBitrate;
    // Determine if there is action required. 
    if (currentBitrate > availableBitrate) {
      newBitrate = availableBitrate;
    }
    else if (audioIssuesFound.length > 0) {
      // Attempt degradation.
      newBitrate = Math.round(currentBitrate / 2);
    }
    else if (currentBitrate < availableBitrate) {
      // If there are no issues above and we have more bitrate avaialble to us, lets increase the bitrate to the estimated avaiable amount. 
      newBitrate = availableBitrate;
    } else {
      // If there are no issues and the currentBitrate is equal to the avaialbleBitrate, try increasing the bitrate up by the step amount. 
      newBitrate = currentBitrate * 2;
    }
    // Don't allow a value below the configured tenant setting. 
    if (newBitrate < this._minAudioBitrate) {
      // TODO: Reduce Video Quality.
      newBitrate = this._minAudioBitrate;
      // Don't allow a value higher then the configured tenant setting. 
    } else if (newBitrate > this._maxAudioBitrate) {
      newBitrate = this._maxAudioBitrate;
    }

    if (this.media?.audioTrack?.isMuted || trk.statAudio.BitrateActual >= (this._maxAudioBitrate * 0.85)) {
      this._videoCanRecover = true;
    } else {
      this._videoCanRecover = false;
    }

    // If there is nothing to do, bail early.
    if (newBitrate === null || newBitrate == currentBitrate) {
      return;
    }

    // Perform a bitrate adjustment.
    this._clientConstraints.audioBitrateMax = newBitrate;
    await this.updateSenderParametersAudio();
  }

  private async calculateConstraints(trk: Track): Promise<void> {
    if (!this._customBandwidthAdaptationEnabled) {
      Log.debug('Custom bandwidth adaptation is disabled');
      return;
    }

    if (!this._rampedUp) {
      Log.debug('Waiting for ramp up to complete');
      return;
    }

    let estimatedPixelCount = this._clientConstraints.videoPixelCountMax ?? this._maxPixelCount;
    let estimatedFrameRate = this._clientConstraints.videoFrameRateMax ?? this._maxFrameRate;
    let estimatedBitrate = this._clientConstraints.videoBitrateMax ?? this._maxBitrate;
    let adjustFrameRate = true;
    let adjustResolution = true;
    let lowJitter = false;

    // Debugging
    const jitter = trk.statVideo.Rtp_Jitter;
    const packetLoss = this._media.videoTrack.getSenderPacketLoss();
    let degraded = jitter >= this._jitterLow || packetLoss >= this._packetLossLow;
    Log.debug(`Degradation Stats: Jitter=${jitter}, PacketLoss=${packetLoss}`);

    if (jitter > this._maxVideoJitterDelay || trk.statVideo.Track_JitterBufferDelay > this._maxVideoJitterDelay) {
      Log.debug(`CalculateVideoConstraints - Jitter or JitterBufferDelay over room's maxAudioJitterDelay property.`);
    }

    // TODO: MK - Try to refactor to simplify this

    let newBitrate = estimatedBitrate;

    if (jitter >= this._jitterHigh) {
      newBitrate = estimatedBitrate * .5;

      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = this._minPixelCount;
        Log.debug(`High Jitter: Jitter=${jitter}, PixelCount=${estimatedPixelCount}`);
      }

      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = this._minFrameRate;
        Log.debug(`High Jitter: Jitter=${jitter}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (jitter >= this._jitterMedium) {
      newBitrate = estimatedBitrate * .75;

      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = estimatedPixelCount / 2;
        Log.debug(`Medium Jitter: Jitter=${jitter}, PixelCount=${estimatedPixelCount}`);
      }

      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = estimatedFrameRate / 2;
        Log.debug(`Medium Jitter: Jitter=${jitter}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (jitter >= this._jitterLow) {
      newBitrate = this._media.videoTrack.targetBitrate;
      lowJitter = true;

      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = estimatedPixelCount * (2 / 3);
        adjustFrameRate = false;
       Log.debug(`Low Jitter: Jitter=${jitter}, PixelCount=${estimatedPixelCount}`);
      } else if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = estimatedFrameRate * (2 / 3);
        Log.debug(`Low Jitter: Jitter=${jitter}, FrameRate=${estimatedFrameRate}`);
      } else {
        lowJitter = false;
      }
    }

    if (packetLoss >= this._packetLossHigh) {
      newBitrate = estimatedBitrate * .5;

      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = Math.min(estimatedPixelCount, this._minPixelCount);
        adjustResolution = true;
        Log.debug(`High Packet Loss: PacketLoss=${packetLoss}, PixelCount=${estimatedPixelCount}`);
      }
      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = Math.min(estimatedFrameRate, this._minFrameRate);
        adjustFrameRate = true;
        Log.debug(`High Packet Loss: PacketLoss=${packetLoss}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (packetLoss >= this._packetLossMedium) {
      newBitrate = estimatedBitrate * .75;

      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = Math.min(estimatedPixelCount, this._clientConstraints.videoPixelCountMax / 2);
        adjustResolution = true;
        Log.debug(`Medium Packet Loss: PacketLoss=${packetLoss}, PixelCount=${estimatedPixelCount}`);
      }
      if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = Math.min(estimatedFrameRate, this._clientConstraints.videoFrameRateMax / 2);
        adjustFrameRate = true;
        Log.debug(`Medium Packet Loss: PacketLoss=${packetLoss}, FrameRate=${estimatedFrameRate}`);
      }
    } else if (packetLoss > this._packetLossLow) {
      newBitrate = this._media.videoTrack.targetBitrate;

      if (this._clientConstraints.videoPixelCountMax > this._minPixelCount) {
        estimatedPixelCount = Math.min(estimatedPixelCount, this._clientConstraints.videoPixelCountMax * (2 / 3));
        adjustFrameRate = false;
        Log.debug(`Low Packet Loss: PacketLoss=${packetLoss}, PixelCount=${estimatedPixelCount}`);
      } else if (this._clientConstraints.videoFrameRateMax > this._minFrameRate) {
        estimatedFrameRate = Math.min(estimatedFrameRate, this._clientConstraints.videoFrameRateMax * (2 / 3));
        Log.debug(`Medium Packet Loss: PacketLoss=${packetLoss}, FrameRate=${estimatedFrameRate}`);
      } else {
        if (!lowJitter) {
          adjustFrameRate = false;
          adjustResolution = false;
        }
      }
    } else {
      // TODO: MK - Find out why this is set or if it's still relevant
      if (lowJitter) {
        adjustFrameRate = false;
        adjustResolution = false;
      }
    }
    
    const localTargetBitrate = VideoUtility.calculateBitrateUsingPixelCount(estimatedPixelCount, estimatedFrameRate);
    let videoShouldRecover = false;

    // If the video isn't degraded due to jitter or packet loss, attempt a recovery
    if (!degraded) {
      this._healthyChecks++;
      this._unhealthyChecks = 0;

      if (this._healthyChecks > 3) {
        videoShouldRecover = true;
      }
    } else {
      this._healthyChecks = 0;
      this._unhealthyChecks++;
      videoShouldRecover = false;

      if (newBitrate < estimatedBitrate) {
        estimatedBitrate = newBitrate;
      }
    }

    // TODO: MK - Revisit the recovery logic. We need to throttle recovery because sometimes we get false positive stats and recover prematurely 
    // and end up scaling back down the next cycle. There should be a health check threshold to make sure we are health for X consecutive cycles 
    // before recovering FPS or Resolution

    if (this._videoCanRecover && videoShouldRecover && this._encodingEnabled) {
      this._healthyChecks = 0;
      estimatedBitrate = Math.min(this._maxBitrate, estimatedBitrate + 200);

      if (this._lastStepType == "pixelCount") {
        this._lastStepType = "frameRate";

        if (this._clientConstraints.videoFrameRateMax < this._maxFrameRate) {
          estimatedFrameRate = Math.min(estimatedFrameRate + (estimatedFrameRate * (1 / 3)), this._maxFrameRate);
          Log.debug(`FrameRate Increase: Current=${this._clientConstraints.videoFrameRateMax}, New=${estimatedFrameRate}`);
          adjustResolution = false;
        }
      } else if (this._lastStepType == "frameRate") {
        this._lastStepType = "pixelCount";

        if (this._clientConstraints.videoPixelCountMax < this._maxPixelCount) {
          estimatedPixelCount = Math.min(estimatedPixelCount + (estimatedPixelCount * (1 / 3)), this._maxPixelCount);
          Log.debug(`PixelCount Increase: Current=${this._clientConstraints.videoPixelCountMax}, New=${estimatedPixelCount}`);
          adjustFrameRate = false;
        }
      }

      Log.debug(`Increasing Constraint: LastStepType=${this._lastStepType}, AdjustFrameRate=${adjustFrameRate}, AdjustResolution=${adjustResolution}`);
    }

    estimatedBitrate = Math.round(estimatedBitrate);
    estimatedPixelCount = Math.round(estimatedPixelCount);

    this._estimatedConstraints.videoBitrateMax = estimatedBitrate;
    this._estimatedConstraints.videoFrameRateMax = estimatedFrameRate;
    this._estimatedConstraints.videoPixelCountMax = estimatedPixelCount;

    if (estimatedFrameRate == this._clientConstraints.videoFrameRateMax) {
      adjustFrameRate = false;
    }

    if (estimatedPixelCount == this._clientConstraints.videoPixelCountMax) {
      adjustResolution = false;
    }

    Log.debug(`Constraint Estimates: Bitrate=${estimatedBitrate}, FrameRate=${estimatedFrameRate}. PixelCount=${estimatedPixelCount}`);
    let updateConstraints = false;

    if (adjustResolution && (estimatedPixelCount >= this._minPixelCount) && (estimatedPixelCount <= this._maxPixelCount)) {
      updateConstraints = true;
      const previous = this._clientConstraints.videoPixelCountMax;
      Log.debug(`Client Constraint Update: Type=PixelCount, Previous=${previous}, New=${estimatedPixelCount}`);
      this._clientConstraints.videoPixelCountMax = estimatedPixelCount;
      this._qualityLimitationReason += qualityLimitationReasonResolution;
      if (estimatedPixelCount < previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonDecrease;
      else if (estimatedPixelCount > previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonIncrease;
    }

    if (adjustFrameRate && (estimatedFrameRate >= this._minFrameRate) && (estimatedFrameRate <= this._maxFrameRate)) {
      updateConstraints = true;
      const previous = this._clientConstraints.videoFrameRateMax;
      Log.debug(`Client Constraint Update: Type=FrameRate, Previous=${this._clientConstraints.videoFrameRateMax}, New=${estimatedFrameRate}`);
      this._clientConstraints.videoFrameRateMax = estimatedFrameRate;
      this._qualityLimitationReason += qualityLimitationReasonFrameRate;
      if (estimatedFrameRate < previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonDecrease;
      else if (estimatedFrameRate > previous) this._qualityLimitationReason += qualityLimitationReasonUs + qualityLimitationReasonIncrease;
    }

    // Ensure we don't go below minimums after scaling down
    estimatedBitrate = Math.max(estimatedBitrate, this._minBitrate);
    estimatedFrameRate = Math.max(estimatedFrameRate, this._minFrameRate);
    estimatedPixelCount = Math.max(estimatedPixelCount, this._minPixelCount);

    if (estimatedBitrate != this._clientConstraints.videoBitrateMax) {
      this._clientConstraints.videoBitrateMax = estimatedBitrate;
      updateConstraints = true;
    }

    let availableBitrate = 0;
    let bitrateIncreasing = false;
    let bitrateDecreasing = false;

    // Both targetBitrate/availableOutgoingBitrate increase as network improves. Use whichever value is available from WebRTC
    if (this._media.videoTrack.targetBitrate > 0) {
      availableBitrate = this._media.videoTrack.targetBitrate;
      bitrateDecreasing = this._media.videoTrack.didTargetBitrateDecrease();
      bitrateIncreasing = this._media.videoTrack.didTargetBitrateIncrease();
    } else if (this._media.videoTrack.availableOutgoingBitrate > 0) {
      availableBitrate = this._media.videoTrack.availableOutgoingBitrate;
      bitrateDecreasing = this._media.videoTrack.didAvailableOutgoingBitrateDecrease();
      bitrateIncreasing = this._media.videoTrack.didAvailableOutgoingBitrateIncrease();
     } else {
     availableBitrate = null;
    }

    // Attempt to pause video only if the bitrate indicators are available from the stats.
    if (availableBitrate) {
      // If the encoding is disabled, the targetBitrate/availableOutgoingBitrate will stop increasing after a certian amount of time
      // so we look for a steady bitrate for x amount of time to attempt recovery
      if (!bitrateDecreasing) this._bitrateSteady++;
      if (this._bitrateSteady >= 10) bitrateIncreasing = true;

      if (bitrateIncreasing) {
        this._bitrateIncreases++;
      }

      if (this._encodingEnabled && this._recoveryTime > 5 && this._unhealthyChecks > 5) {
        this._recoveryTime = 0;
        this._bitrateIncreases = 0;
        this._bitrateSteady = 0;
        this._encodingEnabled = false;
        this._unhealthyChecks = 0;
        updateConstraints = true;
        Log.debug("Disabling video encoding");
      } else {
        if (this._encodingEnabled) this._recoveryTime++;

        // The targetBitrate/availableOutgoingBitrate will not continue to increase past the minBitrate
        // so we look to see if it has increased at least 3 times or remained steady for 3 intervals (10 seconds each) or a combination of both
        // and 5 consecutive successful health checks
        if (!this._encodingEnabled && this._bitrateIncreases >= 3 && this._healthyChecks > 5) {
          Log.debug("Enabling video encoding");

          // Trying to recover, set the values to the absolute minimum to attempt a gradual recovery
          this._clientConstraints.videoBitrateMax = this._minBitrate;
          this._clientConstraints.videoFrameRateMax = this._minFrameRate;
          this._clientConstraints.videoPixelCountMax = this._minPixelCount;
          this._encodingEnabled = true;
          this._healthyChecks = 0;
          updateConstraints = true;
        }
      }
    }

    if (updateConstraints) {
      Log.debug(`Constraint Estimates: Bitrate=${this._clientConstraints.videoBitrateMax}, FrameRate=${this._clientConstraints.videoFrameRateMax}, PixelCount=${this._clientConstraints.videoPixelCountMax}, availableBitrate=${availableBitrate}`);
      await this.updateSenderParametersVideo();
    }
  }

  protected async renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (await this._client.renegotiate({
      mediaType: this._mediaType,
      offer: offer,
    }, abortSignal)).answer;
  }

  public setMedia(media: LocalMedia): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      if (this._media == media) return;
      if (media?.connection && media.connection != this) await media.connection.setMedia(null);
      if (this._media) {
        this._media.stateChanged.unbind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.unbind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.unbind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.frameSizeChanged.unbind(this._onVideoTrackFrameSizeChanged);
        this._media.videoTrack?.streamBound.unbind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.unbind(this._onVideoTrackStreamUnbound);
        this._media.connection = null;
      }
      this._media = media;
      if (this._media) {
        this._media.stateChanged.bind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.frameSizeChanged.bind(this._onVideoTrackFrameSizeChanged);
        this._media.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
        this._media.connection = this;
      }
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  public setPriorityAudio(priorityAudio: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityAudio, "priorityAudio");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityAudio == priorityAudio) return;
      this._priorityAudio = priorityAudio;
      await this.updateSenderParametersAudio();
    });
  }

  public setPriorityVideo(priorityVideo: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityVideo, "priorityVideo");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityVideo == priorityVideo) return;
      this._priorityVideo = priorityVideo;
      await this.updateSenderParametersVideo();
    });
  }

  public async updateStats(): Promise<void> {
    if (this.isTerminated) return;

    this._currentRampUpSeconds++;
    this._rampedUp = this._currentRampUpSeconds >= this._rampUpSeconds;

    //TODO: defensive cof
    const senderList = this.connection.getSenders();
    for (const sender of senderList) {

      if (sender.track != null) {

        //pull sender stats
        const statReport = await sender.getStats();
        const pairStat = new StatisticConnection();
        pairStat.AttendeeId = this.attendeeId;

        //first collect pair details
        for (const [key, value] of statReport.entries()) {

          if (value.type == "candidate-pair") {
            pairStat.Pair_Timestamp = value.timestamp;
            pairStat.Pair_BytesReceived = value.bytesReceived;
            pairStat.Pair_BytesSent = value.bytesSent;
            pairStat.Pair_RoundTripTimeCurrent = value.currentRoundTripTime;
            pairStat.Pair_RoundTripTimeTotal = value.totalRoundTripTime;
            pairStat.Pair_AvailableOutgoing = Math.round(value.availableOutgoingBitrate / 1000);
            pairStat.Pair_RequestsReceived = value.requestsReceived;
            pairStat.Pair_RequestsSent = value.requestsSent;
            pairStat.Pair_ResponsesReceived = value.responsesReceived;
            pairStat.Pair_ResponsesSent = value.responsesSent;
            pairStat.Pair_ConsentRequestsSent = value.consentRequestsSent;
            pairStat.Pair_IsNominated = value.nominated;
          }

          if (value.type == "remote-candidate") {
            pairStat.Rmt_IsRemote = value.isRemote;
            pairStat.Rmt_Ip = value.ip;
            pairStat.Rmt_Port = value.port;
            pairStat.Rmt_Protocol = value.protocol;
            pairStat.Rmt_CandidateType = value.candidateType;
            pairStat.Rmt_IsDeleted = value.deleted;
          }

          if (value.type == "local-candidate") {
            pairStat.Lcl_IsRemote = value.isRemote;
            pairStat.Lcl_Ip = value.ip;
            pairStat.Lcl_Port = value.port;
            pairStat.Lcl_Protocol = value.protocol;
            pairStat.Lcl_CandidateType = value.candidateType;
            pairStat.Lcl_Deleted = value.deleted;
          }

        } //each entry

        if (sender.track.kind == "audio") {

          const audioStat = new StatisticAudio();
          audioStat.AttendeeId = this.attendeeId;
          //NOTE: on server: Attendee.DeviceId;
          audioStat.MediaType = this._mediaType;
          audioStat.CreatedOn = new Date();

          audioStat.TrackIndex = 0;
          audioStat.IsClient = true;

          // TODO: JV: muted is not the right prop to use. I believe it's track.enabled
          audioStat.IsMuted = sender.track.muted;
          //? audioStat.IsNoiseSuppressed = _isAudioNoiseSuppressed;
          //? audioStat.IsPaused = _isAudioPaused;

          audioStat.BitrateConstraint = this.audioBitrateMax;

          //TODO: BitrateAllocation, BitrateServer
          //FramerateActual, FramerateConstraint, FramerateEstimated, FramerateServer

          for (const [key, value] of statReport.entries()) {

            if (value.type == "media-source") {
              audioStat.Src_AudioLevel = value.audioLevel;
              audioStat.Src_TotalAudioEnergy = value.totalAudioEnergy;
              audioStat.Src_TotalSamplesDuration = value.totalSamplesDuration;

              if (audioStat.Src_AudioLevel != null) {
                this._media?.audioTrack?.updateLevel(audioStat.Src_AudioLevel);
              }
            }

            if (value.type == "codec") {
              audioStat.Codec_MimeType = value.mimeType;
              audioStat.Codec_Channels = value.channels;
              audioStat.Codec_ClockRate = value.clockRate;
            }

            if (value.type == "outbound-rtp") {
              audioStat.Rtp_Kind = value.kind;
              audioStat.Rtp_SSRC = value.ssrc;
              audioStat.TrackIdentifier = value.mediaSourceId;
              audioStat.TrackMid = value.mid;

              audioStat.Rtp_PacketsSent = value.packetsSent;
              audioStat.Rtp_RetransmittedPacketsSent = value.retransmittedPacketsSent;
              audioStat.Rtp_BytesSent = value.bytesSent;
              audioStat.Rtp_HeaderBytesSent = value.headerBytesSent;
              audioStat.Rtp_RetransmittedBytesSent = value.retransmittedBytesSent;

              audioStat.Rtp_TotalPacketSendDelay = value.totalPacketSendDelay;
              audioStat.Rtp_NackCount = value.nackCount;

              audioStat.BitrateEstimated = Math.round(value.targetBitrate / 1000);
              audioStat.BitrateActual = this._bitrateAudio.calculate(audioStat.Rtp_BytesSent, value.timestamp);
            }

            if (value.type == "remote-inbound-rtp") {
              audioStat.Rtp_Timestamp = value.timestamp as DOMHighResTimeStamp;
              audioStat.Rtp_PacketsLost = value.packetsLost;
              audioStat.Rtp_Jitter = value.jitter;
              audioStat.Rtp_RoundTripTime = value.roundTripTime;
              audioStat.Rtp_RoundTripTimeMeasurements = value.roundTripTimeMeasurements;
              audioStat.Rtp_RoundTripTimeTotal = value.totalRoundTripTime;
            }

            if (value.type == "transport") {
              audioStat.Transport_Timestamp = value.timestamp as DOMHighResTimeStamp;
              audioStat.Transport_BytesSent = value.bytesSent;
              audioStat.Transport_BytesReceived = value.bytesReceived;
              audioStat.Transport_PacketsSent = value.packetsSent;
              audioStat.Transport_PacketsReceived = value.packetsReceived;
              audioStat.Transport_State = value.dtlsState;
            }

          } //foreach entry

          //only save the stat every polling interval
          if ((this._statsPollingInterval % this._currentStatCount) == 0) {

            const sectionsAudio = [];
            for (const stats of statReport.values()) {
              if ((stats.type != "certificate") && (stats.type != "candidate-pair") && (stats.type != "local-candidate") && (stats.type != "remote-candidate")) {
                //NOTE: debugging only, makes huge results
                //sectionsVideo.push(stats);
                sectionsAudio.push(stats.type);
              }
            }
            audioStat.Json = JSON.stringify(sectionsAudio, null, 2);

            this._statsBatchAudio.push(audioStat);
          }
          this._media.audioTrack.updateStats(audioStat, pairStat);
          const track: Track = this._transceivers.get("audio");

          if (track) {
            track.statAudio = audioStat;
            // TODO: MK - Revisit or remove altogether in the next release
            //await this.calculateAudioConstraints(track);
          }
        } //audio

        if (sender.track.kind == "video") {

          const videoStat = new StatisticVideo();
          videoStat.AttendeeId = this.attendeeId;
          //NOTE: on server: Attendee.DeviceId;
          videoStat.MediaType = this._mediaType;
          videoStat.CreatedOn = new Date();

          videoStat.TrackIndex = 0;
          videoStat.SpatialLayerIndex = 0; //only stats for the incoming
          videoStat.TemporalLayerIndex = 0;

          videoStat.IsClient = true;
          videoStat.IsMuted = sender.track.muted;
          //? videoStat.IsPaused = ;
          //? videoStat.IsDisabled = ;

          videoStat.BitrateConstraint = this.videoBitrateMax;
          videoStat.FramerateConstraint = this.videoFramerateMax;
          videoStat.PixelCountConstraint = this.videoPixelCountMax;

          //TODO:
          //BitrateAllocation, BitrateEstimated, BitrateServer
          //PixelCountEstimated, PixelCountServer
          //FramerateEstimated, FramerateServer

          for (const [key, value] of statReport.entries()) {

            if (value.type == "codec") {
              videoStat.Codec_MimeType = value.mimeType;
              videoStat.Codec_Channels = value.channels;
              videoStat.Codec_ClockRate = value.clockRate;
            }

            if (value.type == "outbound-rtp") {
              videoStat.Rtp_Kind = value.kind;
              videoStat.Rtp_SSRC = value.ssrc;
              videoStat.Rtp_IsRemote = value.isRemote;
              videoStat.TrackIdentifier = value.mediaSourceId;
              videoStat.TrackMid = value.mid;

              videoStat.Rtp_FirCount = value.firCount;
              videoStat.Rtp_PliCount = value.pliCount;
              videoStat.Rtp_NackCount = value.nackCount;

              videoStat.Track_FrameWidth = value.frameWidth;
              videoStat.Track_FrameHeight = value.frameHeight;

              /*
              if (videoStat.ResolutionHeight == null) videoStat.ResolutionHeight = value.frameHeight;
              if (videoStat.ResolutionWidth == null) videoStat.ResolutionWidth = value.frameWidth;
              */

              videoStat.Rtp_PacketsSent = value.packetsSent;
              videoStat.Rtp_RetransmittedPacketsSent = value.retransmittedPacketsSent;
              videoStat.Rtp_BytesSent = value.bytesSent;
              videoStat.Rtp_HeaderBytesSent = value.headerBytesSent;
              videoStat.Rtp_RetransmittedBytesSent = value.retransmittedBytesSent;
              videoStat.Rtp_FramesEncoded = value.framesEncoded;
              videoStat.Rtp_KeyFramesEncoded = value.keyFramesEncoded;
              videoStat.Rtp_TotalEncodeTime = value.totalEncodeTime;
              videoStat.Rtp_TotalEncodedBytesTarget = value.totalEncodedBytesTarget;
              videoStat.Rtp_TotalPacketSendDelay = value.totalPacketSendDelay;
              videoStat.Rtp_FramesPerSecond = value.framesPerSecond;
              videoStat.Rtp_FramesSent = value.framesSent;
              videoStat.Rtp_HugeFramesSent = value.hugeFramesSent;
              videoStat.Rtp_QualityLimitationChanges = value.qualityLimitationResolutionChanges;
              videoStat.Rtp_QualityLimitationReason = value.qualityLimitationReason;
              //TODO: escape or encode, breaks deserialization
              //videoStat.Rtp_QualityLimitationDurations = value.qualityLimitationDurations;
              videoStat.Rtp_EncoderImplementation = value.encoderImplementation;
              videoStat.Rtp_QpSum = value.qpSum;
              videoStat.Rtp_IsPowerEfficient = value.powerEfficientEncoder;
              videoStat.Rtp_ScalabilityMode = value.scalabilityMode;

              videoStat.Rtp_TargetBitrate = Math.round(value.targetBitrate / 1000);
              videoStat.BitrateEstimated = Math.round(value.targetBitrate / 1000);
              videoStat.BitrateActual = this._bitrateVideo.calculate(videoStat.Rtp_BytesSent, value.timestamp);

              if (videoStat.Rtp_FramesPerSecond > 0) {
                videoStat.FramerateActual = videoStat.Rtp_FramesPerSecond;
              }
              else {
                videoStat.FramerateActual = this._framerateVideo.calculate(videoStat.Rtp_FramesSent, value.timestamp);
              }
            }

            if (value.type == "remote-inbound-rtp") {
              videoStat.Rtp_Timestamp = value.timestamp as DOMHighResTimeStamp;
              videoStat.Rtp_PacketsLost = value.packetsLost;
              videoStat.Rtp_Jitter = value.jitter;
              videoStat.Rtp_RoundTripTime = value.roundTripTime;
              videoStat.Rtp_RoundTripTimeMeasurements = value.roundTripTimeMeasurements;
              videoStat.Rtp_RoundTripTimeTotal = value.totalRoundTripTime;
            }

            if (value.type == "media-source") {
              videoStat.Src_FramesPerSecond = value.framesPerSecond;
              videoStat.Src_Frames = value.frames;
              if (videoStat.ResolutionHeight == null) videoStat.ResolutionHeight = value.height;
              if (videoStat.ResolutionWidth == null) videoStat.ResolutionWidth = value.width;
            }

            if (value.type == "transport") {
              videoStat.Transport_Timestamp = value.timestamp as DOMHighResTimeStamp;
              videoStat.Transport_BytesSent = value.bytesSent;
              videoStat.Transport_BytesReceived = value.bytesReceived;
              videoStat.Transport_PacketsSent = value.packetsSent;
              videoStat.Transport_PacketsReceived = value.packetsReceived;
              videoStat.Transport_State = value.dtlsState;
            }

          } //foreach entry

          //aggregations
          if (videoStat.ResolutionHeight == null) {
            videoStat.ResolutionHeight = videoStat.Track_FrameHeight;
          }
          if (videoStat.ResolutionWidth == null) {
            videoStat.ResolutionWidth = videoStat.Track_FrameWidth;
          }
          if ((videoStat.ResolutionHeight != null) && (videoStat.ResolutionWidth != null)) {
            videoStat.PixelCountActual = videoStat.ResolutionHeight * videoStat.ResolutionWidth
          }

          //only save the stat every polling interval
          if ((this._statsPollingInterval % this._currentStatCount) == 0) {

            const sectionsVideo = [];
            for (const stats of statReport.values()) {
              if ((stats.type != "certificate") && (stats.type != "candidate-pair") && (stats.type != "local-candidate") && (stats.type != "remote-candidate")) {
                //NOTE: debugging only, makes huge results
                //sectionsVideo.push(stats);
                sectionsVideo.push(stats.type);
              }
            }
            videoStat.Json = JSON.stringify(sectionsVideo, null, 2);

            this._statsBatchVideo.push(videoStat);
          }
          this._media.videoTrack.updateStats(videoStat, pairStat);

          const track: Track = this._transceivers.get("video");

          if (track) {
            track.statVideo = videoStat;
            // TODO: MK - Revisit or remove altogether in the next release
            //await this.calculateConstraints(track);
          }
        } //video

        this._currentStatCount++;

        //TODO: exception handling and timeout
        //push on batch size
        if (this._currentStatCount >= this._statsBatchSize) {
          const batchAudio = this._statsBatchAudio.splice(0);
          const batchVideo = this._statsBatchVideo.splice(0);
          this.sendNotification({
            type: "clientStats",
            audioStats: batchAudio,
            videoStats: batchVideo
          });
          this._currentStatCount = 0;
        }

      } //has track

    } //foreach sender

  } //updateStats

}