
import { ApiClient, Attendee as LS2Attendee, AttendeeCollection, ChatChannel, ChatMessageEvent, DisplayMedia, HttpClient, Identity, IdentityType, Log, LogLevel, MediaCollection, MediaDeviceManager, Meeting, models, UnauthorizedError, UserMedia, TokenResponse } from "@liveswitch/sdk"
import { defineComponent } from "vue";
import Attendee from "./Attendee.vue"
import Attendees from "./Attendees.vue"
import Chat from "./Chat.vue"
import ErrorDisplay from "./ErrorDisplay.vue"
import FakeDisplayMedia from "@/classes/FakeDisplayMedia";
import FakeUserMedia from "@/classes/FakeUserMedia";
import Gallery from "./Gallery.vue";
import JoinFooter from "./JoinFooter.vue";
import MediaSelection from "./MediaSelection.vue";
import MeetingInfo from "./MeetingInfo.vue";
import Preview from "./Preview.vue";
import QRDisplayMedia from "@/classes/QRDisplayMedia";
import QRFakeDisplayMedia from "@/classes/QRFakeDisplayMedia";
import QRFakeUserMedia from "@/classes/QRFakeUserMedia";
import QRUserMedia from "@/classes/QRUserMedia";
import Spinner from "./Spinner.vue";
import Strip from "./Strip.vue";
import RoomUserManagement from "./RoomUserManagement.vue";
import jwt from "jsonwebtoken";
import * as mnemonicId from 'mnemonic-id';
import * as query from "@/classes/Query";

type BitrateChangeType = "both" | "quality" | "undefined" | "visibility";
type DegradationPreference = "balanced" | "maintain-framerate" | "maintain-resolution" | "undefined";
type MediaAutoStart = "afterJoin" | "afterJoinAudioOnly" | "afterJoinVideoOnly" | "beforeJoin" | "beforeJoinAudioOnly" | "beforeJoinVideoOnly" | "manual";
type MediaSource = "device" | "deviceQR" | "fake" | "fakeQR" | "none" | "undefined";
type MeetingType = "BROADCAST" | "CONFERENCE" | "undefined";
type OptionalBoolean = "no" | "undefined" | "yes";

const apiKeyDefault = process.env.VUE_APP_API_KEY;
const apiKeySecretDefault = process.env.VUE_APP_API_KEY_SECRET;
const audioOnlyDefault = false;
const displayMediaAutoStartDefault: MediaAutoStart = "manual";
const identityServiceClusterUrlDefault = process.env.VUE_APP_CLUSTER_IDENTITY_SERVICE_URL;
const identityServiceUrlBackupDefault = process.env.VUE_APP_IDENTITY_SERVICE_URL_BACKUP;
const identityServiceUrlDefault = process.env.VUE_APP_IDENTITY_SERVICE_URL;
const identityTypeDefault: IdentityType = "externalToken";
const localHealthEnabledDefault: OptionalBoolean = "no";
const logLevelDefault: LogLevel = process.env.VUE_APP_LOGGER_LEVEL as LogLevel ?? "info";
const remoteHealthEnabledDefault: OptionalBoolean = "yes";
const userMediaAutoStartDefault: MediaAutoStart = "beforeJoin";

const useAttendeeListDefault: OptionalBoolean = "yes";
const useCameraDefault: OptionalBoolean = "yes";
const useChatDefault: OptionalBoolean = "yes";
const useMicrophoneDefault: OptionalBoolean = "yes";
const useRemoteMediaDefault: OptionalBoolean = "yes";
const useScreenShareDefault: OptionalBoolean = "yes";

export default defineComponent({
  components: {
    Attendee,
    Attendees,
    Chat,
    ErrorDisplay,
    Gallery,
    JoinFooter,
    MediaSelection,
    MeetingInfo,
    Preview,
    RoomUserManagement,
    Spinner,
    Strip,
  },
  data(): {
    action: string;
    apiKey: string;
    apiKeySecret: string;
    attendeePageSize?: number;
    audioLevelInterval?: number;
    audioOnly: boolean;
    audioPermissionRequired: boolean;
    currentChatChannel: ChatChannel | null; 
    deviceManager: MediaDeviceManager;
    displayMediaAutoStart: MediaAutoStart;
    displayMediaDegradationPreference?: RTCDegradationPreference;
    displayMediaSource: MediaSource;
    displayName?: string;
    externalTurnPassword?: string;
    externalTurnUris?: string;
    externalTurnUsername?: string;
    gatewayUrl: string | null;
    iceRestartEnabled: OptionalBoolean;
    identity: Identity | null;
    identityServiceUrl: string;
    identityServiceUrlBackup?: string;
    identityType: IdentityType;
    instant: boolean;
    joinDisabled: boolean;
    joinError?: Error;
    joinProgress: number,
    leaveDisabled: boolean;
    localDisplayMedia: DisplayMedia | null
    localLoopbackAudio: OptionalBoolean;
    localLoopbackVideo: OptionalBoolean;
    localMediaFallbackEnabled: OptionalBoolean;
    localUserMedia: UserMedia | null;
    logLevel: LogLevel,
    maxVisibleDisplayMedias?: number;
    maxVisibleUserMedias?: number;
    maxVisibleUserMediasDuringDisplayMedia?: number;
    meeting?: Meeting;
    meetingType: MeetingType;
    password?: string;
    persistentAttendee: OptionalBoolean;
    reconnectingDurations: number[];
    reconnectionCount: number;
    recordings: any[];
    redAudioEnabled: OptionalBoolean;
    remoteCompatibilityMode: OptionalBoolean;
    remoteDiscardAudio: OptionalBoolean;
    remoteDiscardVideo: OptionalBoolean;
    remotePixelFeedback: OptionalBoolean;
    roomInfo?: models.RoomInfo;
    roomKey: string;
    roomPasscode?: string;
    roomTimerId: number;
    selectedAudioOutputDevice: string | null;
    selectingUserMedia: boolean;
    supportsTransform: boolean;
    turnEnabled: OptionalBoolean;
    turnRequired: OptionalBoolean;
    turnTcpAllowed: OptionalBoolean;
    turnTlsAllowed: OptionalBoolean;
    turnUdpAllowed: OptionalBoolean;
    useAttendeeList: OptionalBoolean;
    useCamera: OptionalBoolean;
    useChat: OptionalBoolean;
    useExactAudioInputDevice: boolean,
    useExactVideoInputDevice: boolean,
    useMicrophone: OptionalBoolean;
    useRemoteMedia: OptionalBoolean;
    useScreenshare: OptionalBoolean;
    userAccountId?: string;
    userKey?: string;
    userMedia: UserMedia | null;
    userMediaAutoStart: MediaAutoStart;
    userMediaDegradationPreference?: RTCDegradationPreference;
    userMediaSource: MediaSource;
    userReplicationCount?: number;
    userRole?: string;
    username?: string;
    videoFrameSize: string | null;
    viewingBlocked: boolean;
    webRtcDegradationEnabled: OptionalBoolean;
    identityUpdatePromise: Promise<TokenResponse> | null;
  } {
    // parse query params (case insensitive)
    const apiKey = query.getString("apiKey") ?? apiKeyDefault;
    const apiKeySecret = query.getString("apiKeySecret") ?? apiKeySecretDefault;
    const gatewayUrl = HttpClient.gatewayUrl = query.getString("gatewayUrl") ?? null;
    const identityServiceUrl = query.getString("identityServiceUrl") ?? identityServiceUrlDefault;
    const identityServiceUrlBackup = query.getString("identityServiceUrlBackup") ?? identityServiceUrlBackupDefault;
    const logLevel = Log.level = query.getString("logLevel") as LogLevel ?? logLevelDefault;
    if (!apiKey) throw new Error("API Key is missing and required.");
    if (!apiKeySecret) throw new Error("API Key Secret is missing and required.");
    if (!identityServiceUrl) throw new Error("Identity Service URL is missing and required.");
    return {
      action: "",
      apiKey: apiKey,
      apiKeySecret: apiKeySecret,
      attendeePageSize: query.getInt("attendeePageSize"),
      audioLevelInterval: query.getInt("audioLevelInterval"),
      audioOnly: query.getBoolean("audioOnly") ?? audioOnlyDefault,
      audioPermissionRequired: false,
      currentChatChannel: null,
      deviceManager: MediaDeviceManager.shared,
      displayMediaAutoStart: (query.getString("displayMediaAutoStart") ?? displayMediaAutoStartDefault) as MediaAutoStart,
      displayMediaDegradationPreference: (query.getString("displayMediaDegradationPreference") ?? null) as RTCDegradationPreference,
      displayMediaSource: (query.getString("displayMediaSource") ?? "undefined") as MediaSource,
      displayName: (query.getString("displayName") ?? mnemonicId.createNameId()),
      externalTurnPassword: query.getString("externalTurnPassword"),
      externalTurnUris: query.getString("externalTurnUris"),
      externalTurnUsername: query.getString("externalTurnUsername"),
      gatewayUrl: gatewayUrl,
      iceRestartEnabled: (query.getString("iceRestartEnabled") ?? "undefined") as OptionalBoolean,
      identity: null,
      identityServiceUrl: identityServiceUrl,
      identityServiceUrlBackup: identityServiceUrlBackup,
      identityType: query.getString("identityType") as IdentityType ?? identityTypeDefault,
      instant: query.getBoolean("instant") ?? false,
      joinDisabled: false,
      joinError: undefined,
      joinProgress: 0.0,
      leaveDisabled: false,
      localDisplayMedia: null,
      localLoopbackAudio: (query.getString("localLoopbackAudio") ?? "undefined") as OptionalBoolean,
      localLoopbackVideo: (query.getString("localLoopbackVideo") ?? "undefined") as OptionalBoolean,
      localMediaFallbackEnabled: (query.getString("localMediaFallbackEnabled") ?? "undefined") as OptionalBoolean,
      localUserMedia: null,
      logLevel: logLevel,
      maxVisibleDisplayMedias: query.getInt("maxVisibleDisplayMedias"),
      maxVisibleUserMedias: query.getInt("maxVisibleUserMedias"),
      maxVisibleUserMediasDuringDisplayMedia: query.getInt("maxVisibleUserMediasDuringDisplayMedia"),
      meeting: undefined,
      meetingType: (query.getString("meetingType") ?? "undefined") as MeetingType,
      persistentAttendee: (query.getString("persistentAttendee") ?? "undefined") as OptionalBoolean,
      reconnectingDurations: [],
      reconnectionCount: 0,
      recordings: [],
      redAudioEnabled: (query.getString("redAudioEnabled") ?? "yes") as OptionalBoolean,
      remoteCompatibilityMode: (query.getString("remoteCompatibilityMode") ?? "undefined") as OptionalBoolean,
      remoteDiscardAudio: (query.getString("remoteDiscardAudio") ?? "undefined") as OptionalBoolean,
      remoteDiscardVideo: (query.getString("remoteDiscardVideo") ?? "undefined") as OptionalBoolean,
      remotePixelFeedback: (query.getString("remotePixelFeedback") ?? "undefined") as OptionalBoolean,
      roomInfo: undefined,
      roomKey: query.getString("roomKey") ?? mnemonicId.createNameId(),
      roomPasscode: query.getString("roomPasscode"),
      roomTimerId: 0,
      selectedAudioOutputDevice: null,
      selectingUserMedia: false,
      supportsTransform: globalThis.MediaStreamTrackGenerator != undefined && globalThis.MediaStreamTrackProcessor != undefined,
      turnEnabled: (query.getString("turnEnabled") ?? "undefined") as OptionalBoolean,
      turnRequired: (query.getString("turnRequired") ?? "undefined") as OptionalBoolean,
      turnTcpAllowed: (query.getString("turnTcpAllowed") ?? "undefined") as OptionalBoolean,
      turnTlsAllowed: (query.getString("turnTlsAllowed") ?? "undefined") as OptionalBoolean,
      turnUdpAllowed: (query.getString("turnUdpAllowed") ?? "undefined") as OptionalBoolean,
      useAttendeeList: (query.getString("useAttendeeList") ?? useAttendeeListDefault) as OptionalBoolean,
      useCamera: (query.getString("useCamera") ?? useCameraDefault) as OptionalBoolean,
      useChat: (query.getString("useChat") ?? useChatDefault) as OptionalBoolean,
      useExactAudioInputDevice: true,
      useExactVideoInputDevice: true,
      useMicrophone: (query.getString("useMicrophone") ?? useMicrophoneDefault) as OptionalBoolean,
      username: (query.getString("username") ?? mnemonicId.createNameId()),
      userKey: (query.getString("userKey") ?? mnemonicId.createNameId()),
      userAccountId: undefined,
      userRole: undefined,
      useRemoteMedia: (query.getString("useRemoteMedia") ?? useRemoteMediaDefault) as OptionalBoolean,
      useScreenshare: (query.getString("useScreenshare") ?? useScreenShareDefault) as OptionalBoolean,
      userMedia: null,
      userMediaAutoStart: (query.getString("userMediaAutoStart") ?? userMediaAutoStartDefault) as MediaAutoStart,
      userMediaDegradationPreference: (query.getString("userMediaDegradationPreference") ?? null) as RTCDegradationPreference,
      userMediaSource: (query.getString("userMediaSource") ?? "undefined") as MediaSource,
      userReplicationCount: query.getInt("userReplicationCount"),
      videoFrameSize: query.getString("videoFrameSize") ?? null,
      viewingBlocked: false,
      webRtcDegradationEnabled: (query.getString("webRtcDegradationEnabled") ?? "yes") as OptionalBoolean,
      identityUpdatePromise: null
    };
  },
  mounted() {
    //@ts-ignore
    globalThis.__log = Log;

    // @ts-ignore
    globalThis.__deviceManager = this.deviceManager;

    this.updateRoomInfoDeferred();
  },
  watch: {
    async apiKey() {
      this.identity = await this.createIdentity();
    },
    async userAccountId() {
      this.identity = await this.createIdentity();
    },
    async userKey() {
      this.identity = await this.createIdentity();
    },
    async username() {
      this.identity = await this.createIdentity();
    },
    async userRole() {
      this.identity = await this.createIdentity();
    },
    gatewayUrl() {
      HttpClient.gatewayUrl = this.gatewayUrl;
    },
    async identityServiceUrl() {
      this.identity = await this.createIdentity();
    },
    async identityServiceUrlBackup() {
      this.identity = await this.createIdentity();
    },
    async identityType() {
      switch (this.identityType) {
        case 'anonymous':
        case 'credentials':
          this.identityServiceUrl = identityServiceClusterUrlDefault as string;
          break;
        default:
          this.identityServiceUrl = identityServiceUrlDefault as string;
          break;
      }
      this.identity = await this.createIdentity();
    },
    logLevel() {
      Log.level = this.logLevel;
    },
    'meeting.isChatEnabled'(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$nextTick(() => { this.openTab("attendees"); });
      }
      if (newValue && !oldValue) {
        this.meeting?.chat?.messageReceived.bind(this.logChatMessage);
      }
    },
    'meeting.isReady'(newValue) {
      if (newValue) {
        this.$nextTick(() => { this.openTab("attendees"); });
      }      
    },
    async 'userMediaSource'(newValue) {
      if ((newValue != "device" && newValue != "deviceQR") && this.userMedia != null) {
        if (this.userMedia.isStarted) await this.userMedia.stop();
        this.userMedia = null;
        this.selectedAudioOutputDevice = null;
      }
    }
  },
  computed: {
    canModerate() {
      if (this.meeting?.state != "joined") return false;
      const attendee = this.meeting.localAttendee;
      return attendee?.role == "HOST" || attendee?.role == "MODERATOR";
    },
    joinMessage() {
      return `Joining: ${this.joinProgress.toLocaleString(undefined, { style: 'percent' })}`
    }
  },
  methods: {
    async updateIdentity() {
      if(!this.identityUpdatePromise){
        this.identity = await this.createIdentity();
        if(!this.identity)
          return;
        if(!this.identity.identityServiceUrl)
          return;
        
        this.identityUpdatePromise = this.identity.token();
        await this.identityUpdatePromise;
        await this.updateRoomInfo();
        this.identityUpdatePromise = null;
      }
    },
    async createIdentity(): Promise<Identity | null> {
      if (!this.apiKey) return null;
      if (!this.identityServiceUrl) return null;

      const identityServiceUrl = this.identityServiceUrlBackup == undefined ? this.identityServiceUrl : [this.identityServiceUrl, this.identityServiceUrlBackup];
      switch(this.identityType){
        case "credentials":{
          if (!this.username) return null;
          if (!this.password) return null;
          return new Identity({
            identityServiceUrl: identityServiceUrl,
            apiKey: this.apiKey,
            type: "credentials",
            password: this.password,
            username: this.username,
          });
        }
        case "externalToken":
          if(!this.username) return null;
          if(!this.userKey) return null;

          return new Identity({
            identityServiceUrl: identityServiceUrl,
            apiKey: this.apiKey,
            type: "externalToken",
            externalToken: jwt.sign({
              userAccountId: this.userAccountId,
              userKey: this.userKey,
              username: this.username,
            }, this.apiKeySecret, {
              algorithm: "HS256",
              expiresIn: 300, // seconds
            }),
          });
        case "anonymous":
          return new Identity({
            identityServiceUrl: identityServiceUrl,
            apiKey: this.apiKey,
            type: "anonymous",
            displayName: this.displayName,
          });
        default:
          return null;
      }      
    },
    getApiClient(): ApiClient | null {
      return this.identity ? new ApiClient({
        identity: this.identity
      }) : null;
    },
    async playAudio(): Promise<void> {
      const audios = document.getElementsByTagName("audio");
      for (const audio of audios) await audio.play();
      this.audioPermissionRequired = false;
    },
    sanitizeNumber(e: Event): number | undefined {
      const value = (e.target as any)?.value;
      if (value === 0) return 0;
      if (!value) return undefined;
      return parseFloat(value);
    },
    async updateRoomInfo(): Promise<void> {
      if (!this.identity) return;
      
      //update identity obj

      const apiClient = this.getApiClient();
      if (!apiClient) return;

      //@ts-ignore
      globalThis.__roomInfo = null;
      this.roomInfo = (await apiClient.getRoomInfoByKey(this.roomKey)).value;

      //@ts-ignore
      globalThis.__roomInfo = this.roomInfo;
    },
    updateRoomInfoDeferred(): void {
      if (this.roomTimerId) clearTimeout(this.roomTimerId);
      this.roomTimerId = window.setTimeout(async () => {
          this.identity = await this.createIdentity();
          this.updateRoomInfo();
        });
    },
    async join(isRejoin: boolean = false) {
      const joinStart = performance.now();
      this.joinError = undefined;
      this.joinDisabled = true;
      try {
        if (!this.identity) throw new Error("Please provide identity details.");
        if (!this.instant && !this.roomKey) throw new Error("Please provide a room key or select Instant.");

        this.meeting = new Meeting({
          identity: this.identity,
          uploadClientConfiguration:{
            StorageServiceUrlBase: process.env.VUE_APP_STORAGE_SERVICE_URL || "",
            MaxConcurrentDownloads: Number.parseInt(process.env.VUE_APP_STORAGE_MAX_CONCURRENT_DOWNLOADS || "", 10) || 1,
            MaxConcurrentUploads:  Number.parseInt(process.env.VUE_APP_STORAGE_MAX_CONCURRENT_UPLOADS || "", 10) || 1,
            MaxFileLength: Number.parseInt(process.env.VUE_APP_STORAGE_SETTINGS_MAX_FILE_LENGTH || "", 10) || 104857600, //100 MB
            MaxInlineContentLength:  Number.parseInt(process.env.VUE_APP_STORAGE_SETTINGS_MAX_INLINE_CONTENT_LENGTH || "", 10) || 1048576, //1 MB
          }
        });

        // @ts-ignore
        globalThis.__meeting = this.meeting;

        // limit the number of visible medias
        if (this.audioOnly) {
          this.meeting.maxVisibleDisplayMedias = 0;
          this.meeting.maxVisibleUserMedias = 0;
          this.meeting.maxVisibleUserMediasDuringDisplayMedia = 0;
        } else {
          if (this.maxVisibleDisplayMedias != undefined) this.meeting.maxVisibleDisplayMedias = this.maxVisibleDisplayMedias;
          if (this.maxVisibleUserMedias != undefined) this.meeting.maxVisibleUserMedias = this.maxVisibleUserMedias;
          if (this.maxVisibleUserMediasDuringDisplayMedia != undefined) this.meeting.maxVisibleUserMediasDuringDisplayMedia = this.maxVisibleUserMediasDuringDisplayMedia;
        }

        // display media source
        switch (this.displayMediaSource) {
          default:
          case "device":
            await this.meeting.setLocalDisplayMedia(new DisplayMedia(true, !this.audioOnly));
            break;
          case "deviceQR":
            await this.meeting.setLocalDisplayMedia(new QRDisplayMedia(true, !this.audioOnly));
            break;
          case "fake":
            await this.meeting.setLocalDisplayMedia(new FakeDisplayMedia(true, !this.audioOnly));
            break;
          case "fakeQR":
            await this.meeting.setLocalDisplayMedia(new QRFakeDisplayMedia(true, !this.audioOnly));
            break;
          case "none":
            await this.meeting.setLocalDisplayMedia(null);
            break;
        }
         
        switch (this.userMediaSource) {
          default:
          case "device":
            this.initializeUserMedia();
            await this.meeting.setAudioOutputDevice(this.selectedAudioOutputDevice as string);
            await this.meeting.setLocalUserMedia(this.userMedia as UserMedia);            
            break;
          case "deviceQR":
            this.initializeQRUserMedia();
            await this.meeting.setAudioOutputDevice(this.selectedAudioOutputDevice as string);
            await this.meeting.setLocalUserMedia(this.userMedia as UserMedia);            
            break;
          case "fake":
            await this.meeting.setLocalUserMedia(new FakeUserMedia(true, !this.audioOnly));
            break;
          case "fakeQR":
            await this.meeting.setLocalUserMedia(new QRFakeUserMedia(true, !this.audioOnly));
            break;
          case "none":
            await this.meeting.setLocalUserMedia(null);
            break;
        }

        if (this.videoFrameSize) {
          const videoFrameSizes = [
            { width: 640, height: 480, label: "480p" },
            { width: 1280, height: 720, label: "720p" },
            { width: 1920, height: 1080, label: "1080p" }
          ];
          
          const selectedSize = videoFrameSizes.find(size => size.label === this.videoFrameSize);
          if (selectedSize) {
            await this.meeting.localUserMedia.videoTrack.setFrameSize({height: selectedSize.height, width: selectedSize.width});
            console.log(`Set video frame size to ${selectedSize.label}.`);
          }
        }

        // start local display media before joining
        if (this.displayMediaAutoStart.startsWith("beforeJoin")) {
          console.info(`Starting local display media before join...`);
          switch (this.displayMediaAutoStart) {
            case "beforeJoin":
              await this.meeting.localDisplayMedia?.start();
              break;
            case "beforeJoinAudioOnly":
              await this.meeting.localDisplayMedia?.startAudio();
              break;
            case "beforeJoinVideoOnly":
              await this.meeting.localDisplayMedia?.startVideo();
              break;
          }
          console.info(`Started local display media before join.`);
        }

        // start local user media before joining
        if (this.userMediaAutoStart.startsWith("beforeJoin")) {
          console.info(`Starting local user media before join...`);
          switch (this.userMediaAutoStart) {
            case "beforeJoin":
              await this.meeting.localUserMedia?.start();
              break;
            case "beforeJoinAudioOnly":
              await this.meeting.localUserMedia?.startAudio();
              break;
            case "beforeJoinVideoOnly":
              await this.meeting.localUserMedia?.startVideo();
              break;
          }
          console.info(`Started local user media before join.`);
        }
        
        // for enable/disable buttons
        this.localDisplayMedia = this.meeting.localDisplayMedia;
        this.localUserMedia = this.meeting.localUserMedia;

        // reconnection stats
        let reconnectingStartTimestamp = 0;
        this.reconnectionCount = 0;
        this.reconnectingDurations.splice(0);
        this.meeting.reconnecting.bind(async () => {
          this.reconnectionCount++;
          reconnectingStartTimestamp = performance.now();
        });
        this.meeting.joined.bind(async (e) => {
          if (e.previousState == "reconnecting") this.reconnectingDurations.push(performance.now() - reconnectingStartTimestamp);
        });

        if (this.localUserMedia?.audioTrack) {
          this.localUserMedia.audioTrack?.ended.bind(async () => {
            let permissionStatus = undefined;
            try { permissionStatus = await navigator.permissions.query(<any>{name: 'microphone'}); } catch { /*best effort -- not supported on firefox */ }
            console.warn(`Audio track ended. Ensure appropriate permissions are granted. (Microphone permission: ${permissionStatus?.state})`);
          });
        }

        if (this.localUserMedia?.videoTrack) {
          this.localUserMedia.videoTrack?.ended.bind(async (e) => {
            let permissionStatus = undefined;
            try { permissionStatus = await navigator.permissions.query(<any>{name: 'camera'}); } catch { /*best effort -- not supported on firefox */ }
            console.warn(`Video track ended. Ensure appropriate permissions are granted. (Camera permission: ${permissionStatus?.state})`);
          });
        }

        // handle lobby
        this.meeting.stateChanged.bind(async e => {
          console.info(`Meeting state changed: ${e.meeting.state}`);
        });

        // handle kicked
        this.meeting.kicked.bind(async () => {
          alert("You were kicked.");
        });

        // handle blocked
        this.meeting.blocked.bind(async () => {
          alert("You were blocked.");
          this.$emit('blocked');
        });

        // stop local media when we leave
        this.meeting.left.bind(async (e) => {
          await this.localUserMedia?.stop();
          await this.localDisplayMedia?.stop();
          
          // re-join on connection failure
          if (e.meeting.hasFailed) {
            setTimeout(async () => {
              while (this.meeting?.isLeft) {
                  console.info(`Rejoining meeting after connection failure...`);
                  void this.meeting.log({eventName: "rejoinAttempt"});
                  await this.join(true);
                  if (this.joinError) {
                    console.error("Could not rejoin meeting after connection failure.");
                    if (this.joinError instanceof UnauthorizedError) break;
                  } else {
                    console.info(`Rejoined meeting ${this.meeting.id} in room ${this.meeting.roomId} as attendee ${this.meeting.localAttendee.id} after connection failure.`);
                  }
              }
            }, 0);
          }
        });

        // join the meeting
        console.info(`Joining meeting...`);
        await this.meeting.join({
          attendeePageSize: this.meetingType == "BROADCAST" ? 0 : this.attendeePageSize,
          audioLevelInterval: this.audioLevelInterval,
          displayName: this.displayName,
          iceOptions: {
            restartEnabled: this.iceRestartEnabled == "undefined" ? undefined : (this.iceRestartEnabled == "yes"),
          },
          localDisplayOptions: {
            degradationPreference: this.displayMediaDegradationPreference == null ? undefined : this.displayMediaDegradationPreference,
            webRtcDegradationPreferenceEnabled: this.webRtcDegradationEnabled == "yes" ? true : false
          },
          localLoopbackAudio: this.localLoopbackAudio == "undefined" ? undefined : (this.localLoopbackAudio == "yes"),
          localLoopbackVideo: this.localLoopbackVideo == "undefined" ? undefined : (this.localLoopbackVideo == "yes"),
          localUserOptions: {
            degradationPreference: this.userMediaDegradationPreference == null ? undefined : this.userMediaDegradationPreference,
            webRtcDegradationPreferenceEnabled: this.webRtcDegradationEnabled == "yes" ? true : false
          },
          meetingType: this.meetingType == "undefined" ? undefined : this.meetingType,
          onLobby: e => {
            console.info(`Lobby status changed: ${e.status}`);
            switch (e.status) {
              case "admitted":
                this.$emit("admitted");
                break;
              case "waiting":
                console.info("Waiting in the lobby...");
                this.$emit('enterLobby', e.exitLobby);
                break;
            }
          },
          onProgress: e => {
            console.info("Join progress: " + e.progress.toLocaleString(undefined, { style: 'percent' }));
            this.joinProgress = e.progress;      
          },
          passcode: this.roomPasscode,
          persistentAttendee: this.persistentAttendee == "undefined" ? undefined : (this.persistentAttendee == "yes"),
          redAudioEnabled: this.redAudioEnabled == "undefined" ? true : (this.redAudioEnabled == "yes"),
          roomKey: this.instant ? undefined : this.roomKey,
          remoteCompatibilityMode: this.remoteCompatibilityMode == "undefined" ? undefined : (this.remoteCompatibilityMode == "yes"),
          remoteDiscardAudio: this.remoteDiscardAudio == "undefined" ? undefined : (this.remoteDiscardAudio == "yes"),
          remoteDiscardVideo: this.remoteDiscardVideo == "undefined" ? undefined : (this.remoteDiscardVideo == "yes"),
          remotePixelFeedback: this.remotePixelFeedback == "undefined" ? undefined : (this.remotePixelFeedback == "yes"),
          turnOptions: {
            enabled: this.turnEnabled == "undefined" ? undefined : (this.turnEnabled == "yes"),
            externalCredentials: (this.externalTurnUris && this.externalTurnUsername && this.externalTurnPassword) ? {
              password: this.externalTurnPassword,
              uris: this.externalTurnUris.split(",").map(uri => uri.trim()),
              username: this.externalTurnUsername,
            }: undefined,
            required: this.turnRequired == "undefined" ? undefined : (this.turnRequired == "yes"),
            tcpAllowed: this.turnTcpAllowed == "undefined" ? undefined : (this.turnTcpAllowed == "yes"),
            tlsAllowed: this.turnTlsAllowed == "undefined" ? undefined : (this.turnTlsAllowed == "yes"),
            udpAllowed: this.turnUdpAllowed == "undefined" ? undefined : (this.turnUdpAllowed == "yes"),
          },
          userReplicationCount: this.userReplicationCount,
          useAttendeeList: this.useAttendeeList == "undefined" ? undefined : (this.useAttendeeList == "yes"),
          useCamera: this.useCamera == "undefined" ? undefined : (this.useCamera == "yes"),
          useChat: this.useChat == "undefined" ? undefined : (this.useChat == "yes"),
          useMicrophone: this.useMicrophone == "undefined" ? undefined : (this.useMicrophone == "yes"),
          useRemoteMedia: this.useRemoteMedia == "undefined" ? undefined : (this.useRemoteMedia == "yes"),
          useScreenShare: this.useScreenshare == "undefined" ? undefined : (this.useScreenshare == "yes"),
        });
        
        console.info(`Joined meeting ${this.meeting.id} in room ${this.meeting.roomId} as attendee ${this.meeting.localAttendee.id}.`);

        if (this.instant) {
          this.instant = false;
          this.roomKey = this.meeting.roomKey;
          await this.updateRoomInfo();
        }

        // start local display media after joining
        if (this.displayMediaAutoStart.startsWith("afterJoin")) {
          console.info(`Starting local display media after join...`);
          switch (this.displayMediaAutoStart) {
            case "afterJoin":
              await this.meeting.localDisplayMedia?.start();
              break;
            case "afterJoinAudioOnly":
              await this.meeting.localDisplayMedia?.startAudio();
              break;
            case "afterJoinVideoOnly":
              await this.meeting.localDisplayMedia?.startVideo();
              break;
          }
          console.info(`Started local display media after join.`);
        }

        // start local user media after joining
        if (this.userMediaAutoStart.startsWith("afterJoin")) {
          console.info(`Starting local user media after join...`);
          switch (this.userMediaAutoStart) {
            case "afterJoin":
              await this.meeting.localUserMedia?.start();
              break;
            case "afterJoinAudioOnly":
              await this.meeting.localUserMedia?.startAudio();
              break;
            case "afterJoinVideoOnly":
              await this.meeting.localUserMedia?.startVideo();
              break;
          }
          console.info(`Started local user media after join.`);
        }

        if (this.meeting.localUserMedia?.audioTrack) {
          console.info(`Level threshold for speech detection is ${this.meeting.localUserMedia.audioTrack.speechLevel.toFixed(3)}.`);
        }

        if (this.meeting.isChatEnabled && this.meeting.chat) {
          this.currentChatChannel = this.meeting.chat.defaultChannel;
          this.meeting.chat.messageReceived.bind(this.logChatMessage);
        }

        //refresh recordings
        await this.refreshRecordings();

        // audio permission check
        this.audioPermissionRequired = false;
        const audios = document.getElementsByTagName("audio");
        for (const audio of audios) {
          if (audio.paused) {
            try {
              await audio.play();
            } catch {
              this.audioPermissionRequired = true;
            }
          }
        }

        // update query params
        query.set("apiKey", this.apiKey, apiKeyDefault);
        query.set("attendeePageSize", this.attendeePageSize);
        query.set("audioLevelInterval", this.audioLevelInterval);
        query.set("audioOnly", this.audioOnly, audioOnlyDefault);
        // query.set("credentialsUsername", this.credentialsUsername);
        query.set("displayMediaAutoStart", this.displayMediaAutoStart, displayMediaAutoStartDefault);
        query.set("displayMediaDegradationPreference", this.displayMediaDegradationPreference);
        query.set("displayMediaSource", this.displayMediaSource);
        // query.set("externalTokenClusterId", this.externalTokenClusterId);
        // query.set("externalTokenTenantId", this.externalTokenTenantId);
        // query.set("externalTokenUserKey", this.externalTokenUserKey);
        // query.set("externalTokenUsername", this.externalTokenUsername);
        query.set("externalTurnPassword", this.externalTurnPassword);
        query.set("externalTurnUris", this.externalTurnUris);
        query.set("externalTurnUsername", this.externalTurnUsername);
        query.set("gatewayUrl", this.gatewayUrl);
        query.set("iceRestartEnabled", this.iceRestartEnabled);
        query.set("identityServiceUrl", this.identityServiceUrl, identityServiceUrlDefault);
        query.set("identityServiceUrlBackup", this.identityServiceUrlBackup, identityServiceUrlBackupDefault);
        query.set("identityType", this.identityType, identityTypeDefault);
        query.set("localMediaFallbackEnabled", this.localMediaFallbackEnabled);
        query.set("logLevel", this.logLevel, logLevelDefault);
        query.set("maxVisibleDisplayMedias", this.maxVisibleDisplayMedias);
        query.set("maxVisibleUserMedias", this.maxVisibleUserMedias);
        query.set("maxVisibleUserMediasDuringDisplayMedia", this.maxVisibleUserMediasDuringDisplayMedia);
        query.set("meetingType", this.meetingType);
        query.set("persistentAttendee", this.persistentAttendee);
        query.set("redAudioEnabled", this.redAudioEnabled);
        query.set("remoteCompatibilityMode", this.remoteCompatibilityMode);
        query.set("remoteDiscardAudio", this.remoteDiscardAudio);
        query.set("remoteDiscardVideo", this.remoteDiscardVideo);
        query.set("remotePixelFeedback", this.remotePixelFeedback);
        query.set("roomKey", this.roomKey);
        query.set("turnEnabled", this.turnEnabled);
        query.set("turnRequired", this.turnRequired);
        query.set("turnTcpAllowed", this.turnTcpAllowed);
        query.set("turnTlsAllowed", this.turnTlsAllowed);
        query.set("turnUdpAllowed", this.turnUdpAllowed);
        query.set("useAttendeeList", this.useAttendeeList, useAttendeeListDefault);
        query.set("useCamera", this.useCamera, useCameraDefault);
        query.set("useChat", this.useChat, useChatDefault);
        query.set("useMicrophone", this.useMicrophone, useMicrophoneDefault);
        query.set("useRemoteMedia", this.useRemoteMedia, useRemoteMediaDefault);
        query.set("useScreenshare", this.useScreenshare, useScreenShareDefault);
        query.set("userMediaAutoStart", this.userMediaAutoStart, userMediaAutoStartDefault);
        query.set("userMediaDegradationPreference", this.userMediaDegradationPreference);
        query.set("userMediaSource", this.userMediaSource);
        query.set("userReplicationCount", this.userReplicationCount);
        query.set("videoFrameSize", this.videoFrameSize);
        query.set("webRtcDegradationEnabled", this.webRtcDegradationEnabled);
        query.setUrl();
        
        this.action = "";

        void this.meeting.log({
          eventName: isRejoin ? "rejoinSuccess" : "joinSuccess",
          eventDebug: {"roomKey": this.roomKey},
          eventDuration: performance.now() - joinStart
        });
        
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        console.error(error);

        void this.meeting?.log({
          eventName: isRejoin ? "rejoinError" : "joinError", 
          eventDebug: {
            "error": error.message,
            "roomKey": this.roomKey
          },
          eventDuration: performance.now() - joinStart
        });

        this.joinError = error;
      } finally {
        this.joinDisabled = false;
      }
    },
    async leave() {
      if (!this.meeting) return;
      this.leaveDisabled = true;
      try {
        // stop the meeting
        console.info(`Leaving meeting...`);
        await this.meeting.leave();
        console.info(`Left meeting.`);

        if (this.userMedia) this.userMedia = null;
        if (this.selectedAudioOutputDevice) this.selectedAudioOutputDevice = null;
      } catch (error: any) {
        if (!(error instanceof Error)) error = new Error(error);
        console.error(error);
      } finally {
        this.leaveDisabled = false;
      }
    },
    async refreshRecordings() {
      if (!this.meeting) return;
      const apiClient = this.getApiClient();
      if (!apiClient) return;
      const recordings = await apiClient.listMeetingRecordings({ meetingId: this.meeting.id });
      this.recordings = recordings.values.filter(r => r.recordingStatus == "READY");
    },
    async menuChange() {
      try {
        if (!this.meeting) return;
        switch (this.action) {
          case "disableChat":
            await this.meeting.disableChat();
            break;
          case "enableChat": 
            await this.meeting.enableChat();
            break;
          case "muteAudio":
            await this.meeting.muteAudio();
            break;
          case "unmuteAudio":
            await this.meeting.unmuteAudio();
            break;
          case "muteVideo":
            await this.meeting.muteVideo();
            break;
          case "unmuteVideo":
            await this.meeting.unmuteVideo();
            break;
          case "unmuteAudioOnJoin":
            await this.meeting.unmuteAudioOnJoin();
            break;
          case "muteAudioOnJoin":
            await this.meeting.muteAudioOnJoin();
            break;
          case "enableAudioUnmuteOnJoin":
            await this.meeting.enableAudioUnmuteOnJoin();
            break;
          case "disableAudioUnmuteOnJoin":
            await this.meeting.disableAudioUnmuteOnJoin();
            break;
          case "unmuteVideoOnJoin":
            await this.meeting.unmuteVideoOnJoin();
            break;
          case "muteVideoOnJoin":
            await this.meeting.muteVideoOnJoin();
            break;
          case "enableVideoUnmuteOnJoin":
            await this.meeting.enableVideoUnmuteOnJoin();
            break;
          case "disableVideoUnmuteOnJoin":
            await this.meeting.disableVideoUnmuteOnJoin();
            break;
          case "startChat":
            await this.meeting.chat.start();
            break;
          case "stopChat":
            await this.meeting.chat.stop();
            break;
          case "startRecording":
            await this.meeting.startRecording();
            break;
          case "stopRecording":
            await this.meeting.stopRecording();
            break;
          case "disableVideoUnmute":
            await this.meeting.disableVideoUnmute();
            break;
          case "enableVideoUnmute":
            await this.meeting.enableVideoUnmute();
            break;
          case "disableAudioUnmute":
            await this.meeting.disableAudioUnmute();
            break;
          case "enableAudioUnmute":
            await this.meeting.enableAudioUnmute();
            break;
          case "viewBlockedUsers":
            this.viewBlockedUsers();
            break;
          case "suppressNoise":
            await this.meeting.suppressNoise();
            break;
          case "suppressNoiseOnJoin":
            await this.meeting.suppressNoiseOnJoin();
            break;
          case "unsuppressNoise":
            await this.meeting.unsuppressNoise();
            break;
          case "unsuppressNoiseOnJoin":
            await this.meeting.unsuppressNoiseOnJoin();
            break;
        }
      } catch (error) {
        alert(`Meeting operation (${this.action}) failed.\n${error}`);
      } finally {
        this.action = "";
      }
    },
    async viewTab(e: any) {
      this.resetTabs();
      let tab = e.currentTarget.attributes.getNamedItem("data-tab") ? e.currentTarget.attributes.getNamedItem("data-tab").value : "";
      this.updateVisibleTab(tab);
    },
    resetTabs() {
      let tabcontent = document.getElementsByClassName("tabcontent");
      for (let i = 0; i < tabcontent.length; i++) {
        (tabcontent[i] as HTMLDivElement).style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      let tablinks = document.getElementsByClassName("tablinks");
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].classList.remove("active");
      }
    },
    updateVisibleTab(tabId: string) {
      // Show the current tab, and add an "active" class to the button that opened the tab
      const visible = document.getElementById(tabId);
      if (visible) visible.style.display = "block";

      let tablinks = document.getElementsByClassName("tablinks");
      for (let i = 0; i < tablinks.length; i++) {
        if (tablinks[i].id == tabId) {
          tablinks[i].classList.add("active");
          break;
        }
      }

      if (!this.meeting?.chat || !this.meeting?.isChatEnabled) return;

      this.currentChatChannel = tabId == "default" ? this.meeting.chat.defaultChannel : this.meeting.chat.channels.get(tabId);

      if (tabId == "attendees" || tabId == "meeting-info") {
        const chatContainer = document.getElementById("chat-container");
        if (chatContainer == null) return;
        chatContainer.style.display = "none";
        return;
      }

      document.getElementById("chat-container")!.style.display = "block";
      let chats = document.getElementsByClassName("chat-display");
      for (let i = 0; i < chats.length; i++) {          
        chats[i].scrollTop = chats[i].scrollHeight;
      }
      document.getElementById("chat-members")!.style.display = tabId == "default" ? "none" : "block";
    },    
    openTab(channelId: string) {
      this.resetTabs();
      this.updateVisibleTab(channelId);
    },
    async logChatMessage(e: ChatMessageEvent) {
      if (e.message.isAudio) console.info(`Chat message (audio) received in ${e.message.channel.name}: ${e.message.file.name} (${e.message.file.type}, ${e.message.file.size} bytes)`);
      if (e.message.isFile) console.info(`Chat message (file) received in ${e.message.channel.name}: ${e.message.file.name} (${e.message.file.type}, ${e.message.file.size} bytes)`);
      if (e.message.isImage) console.info(`Chat message (image) received in ${e.message.channel.name}: ${e.message.file.name} (${e.message.file.type}, ${e.message.file.size} bytes)`);
      if (e.message.isText) console.info(`Chat message (text) received in ${e.message.channel.name}: ${e.message.text}`);
      if (e.message.isVideo) console.info(`Chat message (video) received in ${e.message.channel.name}: ${e.message.file.name} (${e.message.file.type}, ${e.message.file.size} bytes)`);
    },
    viewBlockedUsers() {
      this.viewingBlocked = true;
    },
    formatPhoneNumber(phoneNumberString: string | undefined) : string | null {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    },
    initializeUserMedia() {
      const userMediaOptions = this.localMediaFallbackEnabled == "undefined" ? undefined : { fallbackIfDeviceNotAvailable: this.localMediaFallbackEnabled == "yes" };
      if (this.userMedia == null) this.userMedia = new UserMedia(true, !this.audioOnly, userMediaOptions);
      // @ts-ignore
      globalThis.__userMedia = this.userMedia;
      
    },
    initializeQRUserMedia() {
      if (this.userMedia == null) this.userMedia = new QRUserMedia(true, !this.audioOnly);
      // @ts-ignore
      globalThis.__userMedia = this.userMedia;
    },
    async selectUserMedia(e: any) {
      this.selectingUserMedia = true;
      e.preventDefault();
      this.initializeUserMedia();
      if (this.userMedia == null) return;
      if (!this.userMedia.isStarted) {
        await this.userMedia.start();
      }
    },
  }
})
