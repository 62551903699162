import AccountType from "../models/AccountType";
import ApiResponse from "../api/models/ApiResponse";
import ChangePasswordRequest from "./models/ChangePasswordRequest";
import ClientInit from "./models/ClientInit";
import DeviceStatus from "./models/DeviceStatus";
import DeviceType from "./models/DeviceType";
import DeviceUtility from "../core/DeviceUtility";
import ForgotPasswordRequest from "./models/ForgotPasswordRequest";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import RegisterRequest from "./models/RegisterRequest";
import RegisterResponse from "./models/RegisterResponse";
import ResetPasswordRequest from "./models/ResetPasswordRequest";
import TokenRequest from "./models/TokenRequest";
import TokenResponse from "./models/TokenResponse";
import Utility from "../core/Utility";
import ServerError from "../core/ServerError";

export default class Client {
  private readonly _httpClients: HttpClient[];
  private readonly _init: ClientInit;

  public get apiKey(): string { return this._init.apiKey; }
  public get maxRetries(): number { return this._httpClients[0].maxRetries; }
  public set maxRetries(value: number) { this._httpClients.forEach(hc => hc.maxRetries = value); }
  public get requestTimeout(): number { return this._httpClients[0].requestTimeout; }
  public set requestTimeout(value: number) { this._httpClients.forEach(hc => hc.requestTimeout = value); }
  public get identityServiceUrl(): string | string[] { return this._init.identityServiceUrl; }

  public constructor(init: ClientInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.apiKey, "init.apiKey");
    Guard.isNotNullOrUndefined(init.identityServiceUrl, "init.identityServiceUrl");
    if (Utility.isArray(init.identityServiceUrl)) Guard.isGreaterThan(init.identityServiceUrl.length, 0, "init.identityServiceUrl");
    this._init = init;
    const identityServiceUrls = Utility.isArray(init.identityServiceUrl) ? <string[]>init.identityServiceUrl : [<string>init.identityServiceUrl];
    this._httpClients = identityServiceUrls.map(isu => HttpClient.withApiKey(this._init.apiKey, isu));
  }

  private async retry<T>(action: (httpClient: HttpClient) => Promise<T>): Promise<T> {
    for (let i = 0; i < this._httpClients.length; i++) {
      try {
        return await action(this._httpClients[0]);
      } catch (error: any) {
        if (i == (this._httpClients.length - 1) || error instanceof ServerError) throw error;
        this._httpClients.push(this._httpClients.shift()); // rotate
      }
    }
  }

  public async changePassword(request: ChangePasswordRequest, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(request, "request");
    Guard.isNotNullOrUndefined(request.email, "request.email");
    Guard.isNotNullOrUndefined(request.oldPassword, "request.oldPassword");
    Guard.isNotNullOrUndefined(request.password, "request.password");
    await this.retry(async httpClient => {
      await httpClient.post("/password/change", {
        email: request.email,
        oldPassword: request.oldPassword,
        password: request.password,
      }, abortSignal);
    });
  }

  public async forgotPassword(request: ForgotPasswordRequest, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(request, "request");
    Guard.isNotNullOrUndefined(request.email, "request.email");
    await this.retry(async httpClient => {
      await httpClient.post("/password/forgot", {
        email: request.email,
      }, abortSignal);
    });
  }

  public async getToken(request: TokenRequest, abortSignal?: AbortSignal): Promise<TokenResponse> {
    Guard.isNotNullOrUndefined(request, "request");
    const deviceStatus: DeviceStatus = DeviceUtility.hasCachedIdentifier() ? "KNOWN" : "NEW";
    const deviceType: DeviceType = "WEB";
    return await this.retry(async httpClient => {
      const response = await httpClient.post("/token", {
        device: {
          deviceIdentifier: DeviceUtility.getIdentifier(),
          devicePlatform: DeviceUtility.platform,
          devicePlatformVersion: DeviceUtility.platformVersion,
          deviceStatus: deviceStatus,
          deviceType: deviceType,
        },
        displayName: request.displayName,
        externalToken: request.externalToken,
        name: request.username,
        password: request.password,
      }, abortSignal);

      if(response.value){
        const tokenResponse = <TokenResponse>{
          token: response.value.token,
          tokenTtl: response.value.tokenTtl,
          clusterAssignment: {
            identityUrl: this._init.identityServiceUrl,
            clusterUrl: response.value.apiServiceUrl,
            meetingUrl: response.value.meetingServiceUrl,
          }
        };

        return tokenResponse;
      }

      return  <TokenResponse>response;
    });
  }

  public async register(request: RegisterRequest, abortSignal?: AbortSignal): Promise<RegisterResponse> {
    Guard.isNotNullOrUndefined(request, "request");
    const accountType: AccountType = "CREDENTIAL";
    return await this.retry(async httpClient => {
      return (<ApiResponse<RegisterResponse>>await httpClient.post("/register", {
        accountType: accountType,
        displayName: request.displayName,
        name: request.username,
        password: request.password,
      }, abortSignal)).value;
    });
  }

  public async resetPassword(request: ResetPasswordRequest, abortSignal?: AbortSignal): Promise<void> {
    Guard.isNotNullOrUndefined(request, "request");
    Guard.isNotNullOrUndefined(request.email, "request.email");
    Guard.isNotNullOrUndefined(request.password, "request.password");
    Guard.isNotNullOrUndefined(request.resetToken, "request.resetToken");
    await this.retry(async httpClient => {
      await httpClient.post("/password/reset", {
        email: request.email,
        password: request.password,
        resetToken: request.resetToken,
      }, abortSignal);
    });
  }
}