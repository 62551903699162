import BaseManager from "../Manager";
import Client from "./Client";
import Connection from "./Connection";
import EventLogger from "../event/Logger";
import LocalMedia from "../media/LocalMedia";
import ManagerInit from "./models/ManagerInit";
import MediaCollection from "../media/MediaCollection";
import MediaEvent from "./models/MediaEvent";
import MediaType from "../media/models/MediaType";
import Reactive from "../core/Reactive";
import ReadOnlyCollectionEvent from "../core/models/ReadOnlyCollectionEvent";
import RemoteMedia from "../media/RemoteMedia";
import RemoteMediaCollection from "../media/RemoteMediaCollection";
import TrackEvent from "./models/TrackEvent";
import TrackType from "../media/models/TrackType";

export default class Manager extends BaseManager<Connection> {
  private readonly _audibleMedias = new Map<MediaType, MediaCollection>();
  private readonly _localMedias = new Map<MediaType, LocalMedia>();
  private readonly _visibleMedias = new Map<MediaType, MediaCollection>();
  
  private _client: Client;
  private _init: ManagerInit;
  private _localLoopbackVideo: boolean;
  private _localLoopbackAudio: boolean;

  private _onAudibleDisplayMediaAdded: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onAudibleDisplayMediaRemoved: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onAudibleUserMediaAdded: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onAudibleUserMediaRemoved: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onMediaActivated: (e: MediaEvent) => void;
  private _onMediaDeactivated: (e: MediaEvent) => void;
  private _onTrackStatusUpdated: (e: TrackEvent) => void;
  private _onVisibleDisplayMediaAdded: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onVisibleDisplayMediaRemoved: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onVisibleUserMediaAdded: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _onVisibleUserMediaRemoved: (e: ReadOnlyCollectionEvent<RemoteMedia>) => void;
  private _requestedAudioDeviceId: string = null;

  public get audioDeviceId(): string { return this.connection?.audioDeviceId ?? null; }
  public get maxRetries(): number { return this._client.maxRetries; }
  public set maxRetries(value: number) { this._client.maxRetries = value; }
  public get requestedAudioDeviceId(): string { return this.connection?.requestedAudioDeviceId ?? this._requestedAudioDeviceId ?? null; }
  public get requestTimeout(): number { return this._client.requestTimeout; }
  public set requestTimeout(value: number) { this._client.requestTimeout = value; }

  public constructor() {
    super();
  }

  public init(init: ManagerInit) {
     super.initialize({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "EdgeManager", init.attendeeId, init.meetingId, init.clusterId),
      meetingId: init.meetingId,
      redAudioEnabled: init.redAudioEnabled,
      type: "edge",
      url: init.edgeServerUrl
    });

    this._init = init;
    this._client = new Client(init);
    this._audibleMedias.set("display", init.audibleDisplayMedias);
    this._audibleMedias.set("user", init.audibleUserMedias);
    this._localLoopbackAudio = init.localLoopbackAudio;
    this._localLoopbackVideo = init.localLoopbackVideo;
    this._visibleMedias.set("display", init.visibleDisplayMedias);
    this._visibleMedias.set("user", init.visibleUserMedias);
    this._onAudibleDisplayMediaAdded = this.onAudibleDisplayMediaAdded.bind(Reactive.wrap(this));
    this._onAudibleDisplayMediaRemoved = this.onAudibleDisplayMediaRemoved.bind(Reactive.wrap(this));
    this._onAudibleUserMediaAdded = this.onAudibleUserMediaAdded.bind(Reactive.wrap(this));
    this._onAudibleUserMediaRemoved = this.onAudibleUserMediaRemoved.bind(Reactive.wrap(this));
    this._onMediaActivated = this.onMediaActivated.bind(Reactive.wrap(this));
    this._onMediaDeactivated = this.onMediaDeactivated.bind(Reactive.wrap(this));
    this._onTrackStatusUpdated = this.onTrackStatusUpdated.bind(Reactive.wrap(this));
    this._onVisibleDisplayMediaAdded = this.onVisibleDisplayMediaAdded.bind(Reactive.wrap(this));
    this._onVisibleDisplayMediaRemoved = this.onVisibleDisplayMediaRemoved.bind(Reactive.wrap(this));
    this._onVisibleUserMediaAdded = this.onVisibleUserMediaAdded.bind(Reactive.wrap(this));
    this._onVisibleUserMediaRemoved = this.onVisibleUserMediaRemoved.bind(Reactive.wrap(this));
  }
  
  private onAudibleDisplayMediaAdded(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._audibleMedias.get("display").tryAdd(e.element);
  }

  private onAudibleDisplayMediaRemoved(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._audibleMedias.get("display").tryRemove(e.element.id);
  }

  private onAudibleUserMediaAdded(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._audibleMedias.get("user").tryAdd(e.element);
  }

  private onAudibleUserMediaRemoved(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._audibleMedias.get("user").tryRemove(e.element.id);
  }
  
  private onMediaActivated(e: MediaEvent): void {
    if (e.trackType == "video") this.tryLinkLocalMedia(e.media);
    if (e.previousAttendeeId) void this.eventLogger?.debug("onEdgeMediaActivated", `Attendee ${e.attendeeId} ${e.mediaType} ${e.trackType} has activated in place of ${e.previousAttendeeId}.`);
    else void this.eventLogger?.debug("onEdgeMediaActivated", `Attendee ${e.attendeeId} ${e.mediaType} ${e.trackType} has activated.`);
  }

  private onMediaDeactivated(e: MediaEvent): void {
    if (e.trackType == "video") this.tryUnlinkLocalMedia(e.media);
    void this.eventLogger?.debug("onEdgeMediaDeactivated", `Attendee ${e.attendeeId} ${e.mediaType} ${e.trackType} has deactivated.`);
  }

  private onTrackStatusUpdated(e: TrackEvent): void {
    if (e.trackType == "audio") return;
    if (e.track.status == "disabled") this.tryUnlinkLocalMedia(e.media);
    else if (e.track.status == "enabled") this.tryLinkLocalMedia(e.media);
  }

  private onVisibleDisplayMediaAdded(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._visibleMedias.get("display").tryAdd(e.element);
  }

  private onVisibleDisplayMediaRemoved(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._visibleMedias.get("display").tryRemove(e.element.id);
  }
  
  private onVisibleUserMediaAdded(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._visibleMedias.get("user").tryAdd(e.element);
  }
  
  private onVisibleUserMediaRemoved(e: ReadOnlyCollectionEvent<RemoteMedia>): void {
    this._visibleMedias.get("user").tryRemove(e.element.id);
  }
  
  private tryLinkLocalMedia(remoteMedia: RemoteMedia): void {
    if (this._localLoopbackVideo) return;
    if (remoteMedia.attendee?.id == this.attendeeId) {
      const localMedia = this._localMedias.get(remoteMedia.type);
      if (!localMedia) return;
      const visibleMedias = this._visibleMedias.get(remoteMedia.type);
      for (const visibleMedia of visibleMedias) {
        if (visibleMedia.id != remoteMedia.id) continue;
        void this.eventLogger?.debug("tryLinkLocalMedia", `Replacing remote ${remoteMedia.type} media with local ${localMedia.type} media...`);
        remoteMedia.linkedLocalMedia = localMedia;
        visibleMedias.tryRemove(remoteMedia.id);
        visibleMedias.tryAdd(localMedia);
      }
    } else if (remoteMedia.linkedLocalMedia) {
      this.tryUnlinkLocalMedia(remoteMedia);
    } else {
      const visibleMedias = this._visibleMedias.get(remoteMedia.type);
      if (!visibleMedias.containsKey(remoteMedia.id)) {
        void this.eventLogger?.debug("tryLinkLocalMedia", `Adding remote ${remoteMedia.type} media...`);
        visibleMedias.tryAdd(remoteMedia);
      }
    }
  }

  private tryUnlinkLocalMedia(remoteMedia: RemoteMedia) {
    if (this._localLoopbackVideo) return;
    if (!remoteMedia.linkedLocalMedia) return;
    const localMedia = this._localMedias.get(remoteMedia.type);
    if (!localMedia) return;
    if (remoteMedia.linkedLocalMedia == localMedia) {
      const visibleMedias = this._visibleMedias.get(remoteMedia.type);
      if (remoteMedia.attendee?.id) {
        void this.eventLogger?.debug("tryUnlinkLocalMedia", `Replacing local ${localMedia.type} media with remote ${remoteMedia.type} media...`);
        visibleMedias.tryReplace(localMedia.id, remoteMedia);
      } else {
        void this.eventLogger?.debug("tryUnlinkLocalMedia", `Removing local ${localMedia.type} media...`);
        visibleMedias.tryRemove(localMedia.id);
      }
    }
    remoteMedia.linkedLocalMedia = null;
  }

  protected async createConnection(): Promise<Connection> {
    const connection = new Connection(Object.assign({
      attendeeId: this.attendeeId,
      client: this._client,
      meetingId: this.meetingId,
    }, this._init));

    if (this._requestedAudioDeviceId) await connection.setAudioDevice(this._requestedAudioDeviceId);

    const remoteAudibleDisplayMedias = connection.getMediasActive("display", "audio");
    const remoteAudibleUserMedias = connection.getMediasActive("user", "audio");
    const remoteVisibleDisplayMedias = connection.getMediasActive("display", "video");
    const remoteVisibleUserMedias = connection.getMediasActive("user", "video");

    for (const remoteAudibleDisplayMedia of remoteAudibleDisplayMedias) this._audibleMedias.get("display").tryAdd(remoteAudibleDisplayMedia);
    for (const remoteAudibleUserMedia of remoteAudibleUserMedias) this._audibleMedias.get("user").tryAdd(remoteAudibleUserMedia);
    for (const remoteVisibleDisplayMedia of remoteVisibleDisplayMedias) this._visibleMedias.get("display").tryAdd(remoteVisibleDisplayMedia);
    for (const remoteVisibleUserMedia of remoteVisibleUserMedias) this._visibleMedias.get("user").tryAdd(remoteVisibleUserMedia);
    
    connection.mediaActivated.bind(this._onMediaActivated);
    connection.mediaDeactivated.bind(this._onMediaDeactivated);
    connection.trackStatusUpdated.bind(this._onTrackStatusUpdated);

    remoteAudibleDisplayMedias.added.bind(this._onAudibleDisplayMediaAdded);
    remoteAudibleDisplayMedias.removed.bind(this._onAudibleDisplayMediaRemoved);
    remoteAudibleUserMedias.added.bind(this._onAudibleUserMediaAdded);
    remoteAudibleUserMedias.removed.bind(this._onAudibleUserMediaRemoved);
    remoteVisibleDisplayMedias.added.bind(this._onVisibleDisplayMediaAdded);
    remoteVisibleDisplayMedias.removed.bind(this._onVisibleDisplayMediaRemoved);
    remoteVisibleUserMedias.added.bind(this._onVisibleUserMediaAdded);
    remoteVisibleUserMedias.removed.bind(this._onVisibleUserMediaRemoved);
    
    return connection;
  }

  protected destroyConnection(connection: Connection): void {
    const remoteAudibleDisplayMedias = connection.getMediasActive("display", "audio");
    const remoteAudibleUserMedias = connection.getMediasActive("user", "audio");
    const remoteVisibleDisplayMedias = connection.getMediasActive("display", "video");
    const remoteVisibleUserMedias = connection.getMediasActive("user", "video");

    for (const remoteAudibleDisplayMedia of remoteAudibleDisplayMedias) this._audibleMedias.get("display").tryRemove(remoteAudibleDisplayMedia.id);
    for (const remoteAudibleUserMedia of remoteAudibleUserMedias) this._audibleMedias.get("user").tryRemove(remoteAudibleUserMedia.id);
    for (const remoteVisibleDisplayMedia of remoteVisibleDisplayMedias) this._visibleMedias.get("display").tryRemove(remoteVisibleDisplayMedia.id);
    for (const remoteVisibleUserMedia of remoteVisibleUserMedias) this._visibleMedias.get("user").tryRemove(remoteVisibleUserMedia.id);

    connection.mediaActivated.unbind(this._onMediaActivated);
    connection.mediaDeactivated.unbind(this._onMediaDeactivated);
    connection.trackStatusUpdated.unbind(this._onTrackStatusUpdated);

    remoteAudibleDisplayMedias.added.unbind(this._onAudibleDisplayMediaAdded);
    remoteAudibleDisplayMedias.removed.unbind(this._onAudibleDisplayMediaRemoved);
    remoteAudibleUserMedias.added.unbind(this._onAudibleUserMediaAdded);
    remoteAudibleUserMedias.removed.unbind(this._onAudibleUserMediaRemoved);
    remoteVisibleDisplayMedias.added.unbind(this._onVisibleDisplayMediaAdded);
    remoteVisibleDisplayMedias.removed.unbind(this._onVisibleDisplayMediaRemoved);
    remoteVisibleUserMedias.added.unbind(this._onVisibleUserMediaAdded);
    remoteVisibleUserMedias.removed.unbind(this._onVisibleUserMediaRemoved);
  }

  //public decreaseBitrate(step?: number): boolean {
  //  if (!this.connection) return false;
  //  this.connection.decreaseBitrate(step);
  //  return true;
  //}

  //public getReceiverStats(mediaType: MediaType, trackType: TrackType): TrackStats[] {
  //  return this.connection?.getTrackReceiverStats(mediaType, trackType);
  //}

  //public increaseBitrate(step?: number): boolean {
  //  if (!this.connection) return false;
  //  this.connection.increaseBitrate(step);
  //  return true;
  //}
  
  public async setAudioDevice(deviceId?: string): Promise<void> {
    const connection = this.connection;
    if (connection) {
      await connection.setAudioDevice(deviceId);
      this._requestedAudioDeviceId = connection.requestedAudioDeviceId;
    } else {
      this._requestedAudioDeviceId = deviceId;
    }
  }
  
  public async useNextAudioDevice(): Promise<void> {
    const connection = this.connection;
    if (!connection) return;
    await connection.useNextAudioDevice();
    this._requestedAudioDeviceId = connection.requestedAudioDeviceId;
  }
  
  public async usePreviousAudioDevice(): Promise<void> {
    const connection = this.connection;
    if (!connection) return;
    await connection.usePreviousAudioDevice();
    this._requestedAudioDeviceId = connection.requestedAudioDeviceId;
  }

  public setLocalMedia(mediaType: MediaType, localMedia: LocalMedia) {
    const previousLocalMedia = this._localMedias.get(mediaType);
    if (previousLocalMedia == localMedia) return;
    const remoteVideos = this.connection?.getMediasActive(mediaType, "video") ?? RemoteMediaCollection.empty;
    if (previousLocalMedia) {
      for (const remoteVideo of remoteVideos) {
        this.tryUnlinkLocalMedia(remoteVideo);
      }
    }
    this._localMedias.set(mediaType, localMedia);
    if (localMedia) {
      for (const remoteVideo of remoteVideos) {
        this.tryLinkLocalMedia(remoteVideo);
      }
    }
  }

  public setMaxVisibleUser(maxVisibleUser: number): Promise<void> {
    return this.connection?.setMaxVisibleUser(maxVisibleUser);
  }
}