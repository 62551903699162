import AcceptedError from "../core/AcceptedError";
import ApiClient from "../api/Client";
import ApiResponse from "../api/models/ApiResponse";
import Client from "./Client";
import Guard from "../core/Guard";
import HttpClient from "../core/HttpClient";
import Identity from "../identity/models/Identity";
import Log from "../logging/Log";
import Reactive from "../core/Reactive";
import ReassignSlotResponse from "./models/ReassignSlotResponse";
import { RoomKeyLength } from "../core/validation/Room";
import SessionInit from "./models/SessionInit";
import SessionModel from "./models/Session";
import Token from "./models/Token";
import TokenOptions from "./models/TokenOptions";
import TokenResponse from "./models/TokenResponse";
import Utility from "../core/Utility";

export default class Session implements SessionModel {
  private readonly _client: Client;
  private readonly _httpClient: HttpClient;
  private readonly _identity: Identity;
  private readonly _refreshToken: () => Promise<void>;

  private _isLeaving: boolean = false;
  private _roomLogId: string = null;
  private _token: Token = null;
  private _tokenTimeout: number = 0;
  private _init: SessionInit = null;

  public get maxRetries(): number { return this._client.maxRetries; }
  public set maxRetries(value: number) { this._client.maxRetries = value; }
  public get requestTimeout(): number { return this._client.requestTimeout; }
  public set requestTimeout(value: number) { this._client.requestTimeout = value; }
  public get clusterId(): string { return this._client.clusterId; }

  public constructor(init: SessionInit) {
    Guard.isNotNullOrUndefined(init, "init");
    Guard.isNotNullOrUndefined(init.identity, "init.identity");
    this._client = new Client(init);
    this._identity = init.identity;
    this._init = init;

    this._httpClient = HttpClient.withTokenFactory(async () => {
      if (Utility.isNullOrUndefined(this._token)) throw new Error("Not joined.");
      
      const token = await this._init.identity.token();
      return {
        baseUrl: new URL(token.clusterAssignment.meetingUrl).origin,
        value: this._token.value
      };
    });

    this._refreshToken = this.refreshToken.bind(Reactive.wrap(this));
  }

  private async getTokenInternal(options: TokenOptions, abortSignal: AbortSignal, retryCounter = 0): Promise<TokenResponse> {
    try {
      if (retryCounter == 0) {
        this._roomLogId = options.roomId ? `(ID: ${options.roomId})` : `(key: ${options.roomKey})`;
        Log.debug(`Getting meeting token for room ${this._roomLogId}...`);
      }
      const response = await this._client.getToken({
        displayName: options?.displayName,
        meetingType: options?.meetingType,
        passcode: options.passcode,
        persistentAttendee: options?.persistentAttendee,
        roomKey: options.roomKey,
        roomId: options.roomId,
      }, abortSignal);
      this._token = { value: response.token, ttl: response.tokenTtl };
      this._tokenTimeout = <any>globalThis.setTimeout(this._refreshToken, (this._token.ttl * 1000) - 60000); // within 1 minute of expiring
      return response;
    } catch (error: any) {
      // handle abort signal
      if (abortSignal?.aborted) throw error;

      // handle retry exhaustion
      if (retryCounter >= this.maxRetries) throw error;

      // handle non-ephemeral errors
      if (!(error instanceof AcceptedError)) throw error;

      // handle retry
      const delay = 200 * Math.pow(2, retryCounter);
      Log.warn(`Could not get meeting token for room ${this._roomLogId}. Trying again after ${delay.toLocaleString()}ms.`, error);
      await Utility.delay(delay);
      return await this.getTokenInternal(options, abortSignal, retryCounter + 1);
    }
  }

  private async refreshToken(): Promise<void> {
    if (this._isLeaving) return;
    try {
      const response = (<ApiResponse<TokenResponse>>await this._httpClient.post("/token/refresh", null)).value;
      this._token = { value: response.token, ttl: response.tokenTtl };
      this._tokenTimeout = <any>globalThis.setTimeout(this._refreshToken, (this._token.ttl * 1000) - 60000); // within 1 minute of expiring
    } catch (error: any) {
      if (Utility.isNodeProcess()) {
        // log and swallow. This process happens outside calling code and can't be caught by the process
        console.error(`Failed to refresh meeting token.`, error);
        return;
      }
      throw error;
    }
  }

  public async getToken(options?: TokenOptions, abortSignal?: AbortSignal): Promise<TokenResponse> {
    options ??= {};
    if (options.roomKey) Guard.isLengthWithinBounds(options.roomKey, "options.roomKey", RoomKeyLength.Max, RoomKeyLength.Min);
    if (options.roomId || options.roomKey) return await this.getTokenInternal(options, abortSignal);
    Log.debug(`Creating meeting...`);
    const response = (await new ApiClient({
      identity: this._identity
    }).createMeeting({
      passcode: options.passcode,
      planType: "2TIER",
    }, abortSignal)).value;
    options.roomId = response.roomId;
    options.roomKey = response.roomKey;
    return await this.getTokenInternal(options, abortSignal);
  }

  public leave(): void {
    this._isLeaving = true;
    if (this._token) globalThis.clearTimeout(this._tokenTimeout);
  }
  
  public async reassignEdge(abortSignal?: AbortSignal): Promise<ReassignSlotResponse> {
    if (Utility.isNullOrUndefined(this._httpClient)) throw new Error("Not joined.");
    return (<ApiResponse<ReassignSlotResponse>>await this._httpClient.post("/reassign/edge", null, abortSignal)).value;
  }

  public async token(abortSignal?: AbortSignal): Promise<Token> {
    return this._token;
  }
}
